import React, { useEffect, useState } from 'react'
import baseURL from './baseurl'

export default function Risktocapitalcalculator() {
    const [margindeposit, setmargindeposit] = useState(1000)
    const [numberoflots, setnumberoflots] = useState(1)
    const [initialcapital, setinitialcapital] = useState(100000)
    const [result, setresult] = useState(0.005)
    const [validationmargindeposit,setvalidationmargindeposit] = useState(false)
    const [validationnumberoflots,setvalidationnumberoflots] = useState(false)
    const [validationinitialcapital,setvalidationinitialcapital] = useState(false)

    useEffect(()=>{
        baseURL.get('/get-data/calculator-data').then(async res=>{
            await setmargindeposit(res.data.margindeposit)
            await setresult(((res.data.margindeposit / 2) * numberoflots) / initialcapital)
        })
    },[])
    const submitdata = (e) => {
        e.preventDefault()
        if(isNaN(margindeposit)){setvalidationmargindeposit(true)
            setvalidationnumberoflots(false)
            setvalidationinitialcapital(false)}
        else if(isNaN(numberoflots)){setvalidationmargindeposit(false) 
            setvalidationnumberoflots(true)
            setvalidationinitialcapital(false)}
        else if(isNaN(initialcapital)){setvalidationinitialcapital(true)
        setvalidationmargindeposit(false) 
        setvalidationnumberoflots(false)}
        else{
            setvalidationmargindeposit(false)
            setresult(((margindeposit / 2) * numberoflots) / initialcapital)
        }
    }
    return (
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-xl-10">
                    <div class="calculator-box d-flex justify-content-between">
                        <div class="calculator-form col-xl-6">
                            <form onSubmit={(e) => submitdata(e)}>
                                <div class="form-group">
                                    <label for="currencypair">Margin Deposit</label>
                                    <input type="number" class="form-control" id="currencypair"
                                        value={margindeposit} onChange={(e) => setmargindeposit(parseFloat(e.target.value))} />
                                    {validationmargindeposit && <div class="validation-bar">*Enter amount</div>}
                                </div>
                                <div class="form-group">
                                    <label for="currencypair">Number of Lots</label>
                                    <input type="number" class="form-control" id="currencypair"
                                        value={numberoflots} onChange={(e) => setnumberoflots(parseFloat(e.target.value))} />
                                    {validationnumberoflots && <div class="validation-bar">*Enter number of lots</div>}
                                </div>
                                <div class="form-group">
                                    <label for="currencypair">Initial Capital</label>
                                    <input type="number" class="form-control" id="currencypair"
                                        value={initialcapital} onChange={(e) => setinitialcapital(parseFloat(e.target.value))} />
                                    {validationinitialcapital && <div class="validation-bar">*Enter initial amount</div>}
                                </div>
                                <button class="btn btn-primary" type="submit">Calculate</button>
                            </form>
                        </div>
                        <div class="col-xl-6 d-flex blue-box pl-5 align-items-center">
                            <div>
                            <p class="mb-0">Risk To Capital</p> <h5 class="mb-0"> {result}</h5>
                            <p class="mb-0">Ratio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
