import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function Forextradingaccount() {
  useEffect(() => {
    Aos.init({
      duration: 2000
    })
  })
  return (
    <div>
      <Navbar />
      <section class="Performance-subheading">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="text-center">
                <h4>Get Started</h4>
              </div>
            </div>
          </div>

        </div>

      </section>
      <section id="steps" class="steps">
        <div class="container">

          <div class="row no-gutters aos-init aos-animate" data-aos="fade-up">
            <div class="col-xl-10" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
              <div class="  px-5">

                <div>
                  <h5 class="  my-4"> Open a Forex Trading Account</h5>
                  <p>In order to benefit from our profitable Forex Signals service, you need a <b>MetaTrader 4</b> trading account opened with a Forex broker.</p>
                  <p>You can choose any licensed Forex broker you consider appropriate in a Tier 1 well regulated market (US, UK, Europe, Australia) that allows copy trading.</p>
                  <p>The procedures of opening a trading account are more or less similar among the vast majority of brokers licensed by prominent financial authorities (FCA, CySEC, AFSL, etc).</p>
                  <b>Please note a broker might have various trading services so please select the MetaTrader 4 trading option. </b>
                  <p><b>Here is the procedure for opening a MetaTrader  trading account with Oanda.com</b></p>
                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4" style={{ position: "sticky", top: "300px" }}> <span >1</span>Click on the button “Start Trading”</h5>

                  <img src="/assets/1.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                  <p class="mx-5">Go to <a href="https://www.oanda.com/eu-en/trading/">https://www.oanda.com/eu-en/trading/</a> On the home page click on the button “Start Trading”. Other brokers will probably have another name like “Create account” or something similar. In general, look for a prominent button highlighted by a different color, usually in the upper right corner.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >2</span>Choose the country of your residence</h5>

                  <img src="/assets/2.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                  <p class="mx-5">Confirm or choose the country of your residence, click “Confirm and Continue” (read the documents presented before continuing).</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >3</span>Create new account</h5>

                  <img src="/assets/3.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image" />
                  <p class="mx-5">Complete the information requested on the form “Create New Account”. You have to indicate a username, an email address, a password, and a security question. Click “Confirm and continue”.</p>

                </div>


                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >4</span>Personal details</h5>

                  <img src="/assets/4.png" class="img-fluid  mx-5 mb-3" width="400px" />
                  <p class="mx-5">Complete your personal details like name, date of birth, phone number, and tax information: </p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >5</span>Address</h5>

                  <img src="/assets/5.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                  <p class="mx-5">Complete the details regarding your residential address.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >6</span>Employment</h5>

                  <img src="/assets/6.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                  <p class="mx-5"> Fill in the information regarding your employment status.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >7</span>Trading experience</h5>

                  <img src="/assets/7.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                  <p class="mx-5">The next step is an assessment of your trading experience.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >8</span>Knowledge Assessment</h5>

                  <img src="/assets/8.png" class="img-fluid  mx-5 mb-3" width="400px" />
                  <p class="mx-5">Complete the Knowledge Assessment, a set of 9 questions related to trading Forex and CFDs.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >9</span>Verify your identity</h5>


                  <p class="mx-5">Verify your identity. Upload the requested documents to verify your address and identity, meaning an ID card, passport or driving license, and a bank statement or utility bill issued within the last 6 months.</p>

                </div>

                <div class="primary-card-shadow1 pb-2 mb-4">
                  <h5 class=" heading mt-4"> <span >10</span>Confirmation</h5>


                  <p class="mx-5">You will receive an email confirming that your application was received and another one when your account was activated.</p>

                </div>



              </div>
              <div class="mb-5 text-center"><Link to="/get-started/copy-trading/metatrader" ><button class="btn btn-primary">Next</button></Link></div>
            </div>


          </div>

        </div>
      </section>
      <Footer />
    </div>
  )
}
