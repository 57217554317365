import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
             <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-12">
                                <img src='assets/logo.svg' alt="logo" class="img-fluid" />
                                <p>Fort Knox Global is an Asset Management Company that specializes in managing forex investment portfolios.</p>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="detail">
                                    <h4>Our Office</h4>
                                    <p style={{maxWidth:"250px"}}>100 King Street West, Suite 5600, Toronto, Ontario. Canada.  M5X 1C9 </p>
                                </div>
                                <div class="detail">
                                    <h4>Call Us</h4>
                                    <a href="tel:+234-800-456-478">+1 (416) 479-5457</a>
                                <div>Monday to Friday 9am to 5pm EST</div>
                                </div>
                                <div class="detail">
                                    <h4>Email Us</h4>
                                    <p><a href="mailto:info@fortknoxglobal.com"> info@fortknoxglobal.com</a></p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <h4>Important Links</h4>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/products">Products</Link></li>
                                    <li><Link to="/performance">Performance History</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-and-condition">Terms & Conditions</Link></li>
                                    <li ><Link to="/faq">FAQs</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
        </div>
    )
}
