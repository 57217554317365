import React from 'react';
import {BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import Home from './home';
import Performance from './performance';
import Calculator from './calculator';
import './App.css';
import About from './about';
import Products from './products';
import Learn from './learn';
import Getstarted from './getstarted';
import FAQ from './Faq';
import Forextradingaccount from './forextradingaccount';
import Metatraderplatform from './metatraderplatform'
import Openmql5account from './openmql5account';
import Privacypolicy from './privacypolicy';
import Termandcondition from './termandcondition';
import Signalcopytrading from './signalcopytrading';
import Pammaccount from './pammaccount';
import MetatraderWindows from './metatraderwindows';
import MetatraderMacOS from './metatradermacos';

function App(props) {
  return (
    <div className="App">
      <Router basename='/'>
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/performance' component={Performance}></Route>
          <Route exact path='/calculator' component={Calculator}></Route>
          <Route exact path='/about' component={About}></Route>
          <Route exact path='/products' component={Products}></Route>
          <Route exact path='/learn' component={Learn}></Route>
          <Route exact path='/getstarted' component={Getstarted}></Route>
          <Route exact path='/faq' component={FAQ}></Route>
          <Route exact path='/getstarted/signal-copy-trading' component={Signalcopytrading}></Route>
          <Route exact path='/get-started/copy-trading/metatrader' component={Metatraderplatform}></Route>
          <Route exact path='/get-started/copy-trading/metatrader/windows' component={MetatraderWindows}></Route>
          <Route exact path='/get-started/copy-trading/metatrader/mac-os' component={MetatraderMacOS}></Route>
          <Route exact path='/get-started/copy-trading/forexaccount' component={Forextradingaccount}></Route>
          <Route exact path='/get-started/copy-trading/mql' component={Openmql5account}></Route>
          {/* <Route exact path='/getstarted/signal-copy-trading' component={Metatraderplatform}></Route> */}
          <Route exact path='/getstarted/pamm-account' component={Pammaccount}></Route>
          <Route exact path='/terms-and-condition' component={Termandcondition}></Route>
          <Route exact path='/privacy-policy' component={Privacypolicy}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
