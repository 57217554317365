import React, { Component } from 'react'
import Navbar from './navbar';
import Footer from './footer';
import Profitcalculator from './profitcalculator';
import Averagepositioncalculator from './averagepositioncalculator';
import Risktocapitalcalculator from './risktocapitalcalculator';
import Margincalculator from './margincalculator';
import Positionsizecalculator from './positionsizecalculator';
import Compoundcalculator from './compoundcalculator';
import Drawdowncalculator from './drawdowncalculator';
import Loancalculator from './loancalculator';
import Subscriptionroi from './subscriptionroi';
import Subscriptioncapital from './subscriptioncapital';
import Ruleof72calculator from './ruleof72calculator';
import Subscriptioncashflow from './subscriptioncashflow';
import Aos from 'aos';
import { Helmet } from 'react-helmet';

export default class Calculator extends Component {
    state = {
        showcal: <Compoundcalculator />,
        inputvalue: "Compounding Calculator"
    }
    changecal(e) {
        e.preventDefault()
        var calculators = [
            { name: "Compounding Calculator", value: <Compoundcalculator /> },
            { name: "Profit Calculator", value: <Profitcalculator /> },
            { name: "Average Calculator", value: <Averagepositioncalculator /> },
            { name: "Risk to Capital Calculator", value: <Risktocapitalcalculator /> },
            { name: "Margin Calculator", value: <Margincalculator /> },
            { name: "Position Size Calculator", value: <Positionsizecalculator /> },
            { name: "Drawdown Calculator", value: <Drawdowncalculator /> },
            { name: "Loan Calculator", value: <Loancalculator /> },
            { name: "Subscription ROI", value: <Subscriptionroi /> },
            { name: "Subscription Capital", value: <Subscriptioncapital /> },
            { name: "Rule of 72 Calculator", value: <Ruleof72calculator /> },
            { name: "Subscription Cashflow", value: <Subscriptioncashflow /> },
        ]
        for (var i = 0; i < calculators.length; i++) {
            if (e.target.value == calculators[i].name) {
                this.setState({ showcal: calculators[i].value })
            }
        }
    }
    componentDidMount() {
        Aos.init({
            duration: 2000
        })
    }
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="forex calculators" />
                    <meta name="description" content="Use these easy Forex calculators to forecast your returns, balance your trading positions and assess your results" />
                    <title>Forex Calculators - tools to assess the trading performance </title>
                </Helmet>
                <Navbar />
                <section class="Performance-subheading">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h4>Calculator</h4>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section class="calculator-tab">
                    <div class="container">
                        <div class="row d-flex justify-content-center border-bottom">
                            <div class="col-xl-8 d-flex justify-content-center ">
                                <form class="form-inline">
                                    <div class="form-group  selct-calculator">

                                        <h5>Select Calculator :</h5>
                                        <select class="form-control form-control" onChange={(e) => this.changecal(e)}>
                                            <option selected>Compounding Calculator</option>
                                            <option>Profit Calculator</option>
                                            <option>Average Calculator</option>
                                            <option>Risk to Capital Calculator</option>
                                            <option>Margin Calculator</option>
                                            <option>Position Size Calculator</option>
                                            <option>Drawdown Calculator</option>
                                            <option>Loan Calculator</option>
                                            <option>Subscription ROI</option>
                                            <option>Subscription Capital</option>
                                            <option>Rule of 72 Calculator</option>
                                            <option>Subscription Cashflow</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <div> {this.state.showcal} </div>
                {/* <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <Compoundcalculator />
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <Profitcalculator /> </div>

                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <Averagepositioncalculator />
                    </div>

                    <div class="tab-pane fade" id="pills-risk" role="tabpanel" aria-labelledby="pills-risk-tab">
                        <Risktocapitalcalculator />
                    </div>
                    <div class="tab-pane fade" id="pills-margin" role="tabpanel" aria-labelledby="pills-margin-tab">
                        <Margincalculator />
                    </div>
                    <div class="tab-pane fade" id="pills-size" role="tabpanel" aria-labelledby="pills-size-tab">
                        <Positionsizecalculator />
                    </div>
                </div> */}
                <Footer />

            </div>
        )
    }
}
