import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
export default function Pammaccount() {
    return (
        <div>
            <Navbar />
      <section class="Performance-subheading">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="text-center">
                <h4>Coming Soon</h4>
              </div>
            </div>
          </div>

        </div>

      </section>
      <div class="text-center my-5">Contact us at <a href="https://fortknoxglobal.com">info@fortknoxglobal.com</a> for more details</div>
      <Footer />
        </div>
    )
}
