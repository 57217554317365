import React, { Component } from 'react'
import { ThemeProvider } from 'react-bootstrap';
import baseURL from './baseurl';
import { Helmet } from 'react-helmet';

export default class Profitcalculator extends Component {
    state = {
        type: "Buy",
        lots: 1,
        openprice: 1.3932,
        closeprice: 1.3943,
        profit: 11,
        profitinpips: 110,
        validationlots: false,
        validationopenprice: false,
        validationcloseprice: false
    }
    submitdata = async (e) => {
        e.preventDefault()
        if (isNaN(this.state.lots)) { await this.setState({ validationlots: true }) }
        else if (isNaN(this.state.openprice)) { await this.setState({ validationlots: false, validationopenprice: true }) }
        else if (isNaN(this.state.closeprice)) { await this.setState({ validationlots: false, validationopenprice: false, validationcloseprice: true }) }
        else {
            if (this.state.type === "Buy") {
                await this.setState({
                    profitinpips: (-(this.state.openprice - this.state.closeprice) * 10000),
                    profit: (-(this.state.openprice - this.state.closeprice) * 10000) * this.state.lots * 10
                })
            }
            else {
                this.setState({
                    profitinpips: (this.state.openprice - this.state.closeprice) * 10000,
                    profit: ((this.state.openprice - this.state.closeprice) * 10000) * this.state.lots * 10
                })
            }
        }
    }
    render() {
        return (
            <div class="calculator">
            <div class="container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Forex Profit calculator" />
                    <meta name="description" content="Forex Profit Calculator is the tool that forecasts for you the potential profits that may be obtained from a certain position placed on the Forex market" />
                    <title>Forex Profit Calculator - Forecast potential profits</title>
                </Helmet>
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-10">
                        <div class="calculator-box d-flex justify-content-between">
                            <div class="calculator-form">
                                <h4 >Please enter the following information</h4>
                                <form onSubmit={(e) => this.submitdata(e)}>
                                    <div class="row">
                                        <div class="form-group col-6">
                                            <label for="currencypair">Currency pair</label>
                                            <input type="text" class="form-control" id="currencypair" value="GBPUSD" />
                                        </div>
                                        <div class="form-group col-6">
                                            <label for="depositcurrency">Deposit Currency</label>
                                            <input type="text" class="form-control" id="depositcurrency" value="USD" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-6">
                                            <label class="mr-sm-2" for="buyorsell">Buy or Sell</label>
                                            <select value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })} class="custom-select mr-sm-2" id="buyorsell">
                                                <option >Buy</option>
                                                <option >Sell</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-6">
                                            <label for="lots">Lots(Trade Size)</label>
                                            <input type="number" class="form-control" id="lots"
                                                value={this.state.lots} onChange={(e) => this.setState({ lots: parseFloat(e.target.value) })} />
                                            {this.state.validationlots && <div class="validation-bar">*enter lot value</div>}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-6">
                                            <label for="openprice">Openprice</label>
                                            <input type="number" class="form-control" id="openprice"
                                                value={this.state.openprice} onChange={(e) => this.setState({ openprice: parseFloat(e.target.value) })} />
                                            {this.state.validationopenprice && <div class="validation-bar">*enter openprice value</div>}
                                        </div>
                                        <div class="form-group col-6">
                                            <label for="closeprice">Closeprice</label>
                                            <input type="number" class="form-control" id="closeprice"
                                                value={this.state.closeprice} onChange={(e) => this.setState({ closeprice: parseFloat(e.target.value) })} />
                                            {this.state.validationcloseprice && <div class="validation-bar">*enter closeprice value</div>}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="pipsize">GBPUSD 1 pip size</label>
                                        <input type="text" class="form-control" id="pipsize" value="0.0001" />
                                    </div>

                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                            <div class="blue-box d-flex pl-5 align-items-center">
                                <div >
                                    <p class="mb-0">Profit</p><h5 class="mb-0"> {Math.round(this.state.profit)}</h5>
                                    <div class="border-center"></div>
                                    <p class="mb-0">Pips</p><h5 class="mb-0"> {Math.round(this.state.profitinpips)}</h5>
                                    <p>Profit in pips</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="defination">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="defination-box">
                                    <h2>Definition</h2>
                                    <p>  A Forex Profit Calculator is a tool that helps investors simulate their trades on the market. And see if the trade they want to place is profitable or not and what is its potential gain/loss. You have to insert the requested values and the profit calculator shows you how many pips you earn/lose on a trade and how much money.</p>
                                   
                                </div>


                                <div class="defination-box">
                                    <h2> Terms of the Forex Profit Calculator </h2>
                                    <h4>Inputs</h4>
                                    <ul >
                                        <li >
                                            <b> Currency pair:  </b>   Choose the pair that you want to place the trade on. For example GBPUSD.
                                        </li>
                                        <li >
                                            <b> Deposit currency: </b>  Indicate your trading account currency. For example USD.
                                        </li>
                                        <li >
                                            <b>Buy or sell: </b>  Insert the type of order you want to place on the market: buy or sell. For example: buy GBPUSD.
                                        </li>
                                        <li >
                                            <b> Open price: </b>  The level of price at which you want to place your trade on the market. For example 1.3766 on GBPUSD.
                                        </li>
                                        <li >
                                            <b> Close price:  </b>  The level of price at which you intend to close your trade. For example 1.3900 on GBPUSD.
                                        </li>
                                    </ul>
                                    <h4>Result</h4>

                                    <ul >
                                        <li >
                                            <b>The Profit Calculator will calculate for you the profit in pips and currency. </b>  The calculator will indicate the approximative rate of interest you need to obtain to double your money in x years.
                                        </li>
                                    </ul>
                                    <h4>Example</h4>
                                    <p> Let’s say you forecast that the GBPUSD quote will go up and you intend to buy this instrument. You have a USD trading account. You want to execute the buy order when the price hits the level of 1.3750 and close the order when the market reaches the level of 1.3900. And you want to buy 0.3 lots.
                                        The profit calculator will calculate for you the number of pips - 150 and the dollar amount you will gain - $450.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

            </div>
            </div>
        )
    }
}
