import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

export default function Positionsizecalculator() {
    const [currencypair, setcurrencypair] = useState("GBPUSD")
    const [depositcurrency, setdepositcurrency] = useState("USD")
    const [pips, setpips] = useState(200)
    const [accountbalance, setaccountbalance] = useState(100000)
    const [pipssize, setpipssize] = useState(0.0001)
    const [risk, setrisk] = useState(2)
    const [resultlots, setresultlots] = useState(1)
    const [resultunits, setresultunits] = useState(100000)
    const [resultmoney, setresultmoney] = useState(2000)
    const [validationrisk, setvalidationrisk] = useState(false)

    const submitdata = (e) => {
        e.preventDefault()
        // alert(`${}`)
        if (risk > 100 || risk < 0) { setvalidationrisk(true) }
        else {
            setvalidationrisk(false)
            setresultlots((accountbalance * risk * 100) / (pips * 100000))
            setresultunits((accountbalance * risk * 100) / pips)
            setresultmoney((accountbalance * risk) / 100)
        }
    }
    return (
        <div class="container">
              <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Position size Calculator" />
                    <meta name="description" content="Position Size Calculator shows you what dimension in lots should your position have to respect the established risk policy" />
                    <title>Position Size Calculator - Manage trading risks better</title>
                </Helmet>
            <div class="row d-flex justify-content-center">
                <div class="col-xl-10 ">
                    <div class=" calculator-box d-flex justify-content-between">
                        <div class="calculator-form col-xl-6">
                            <form onSubmit={(e) => submitdata(e)}>
                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <label for="currencypair">Currency pair</label>
                                        <input type="text" class="form-control" id="currencypair"
                                            value={currencypair} />
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="depositcurrency">Deposit Currency</label>
                                        <input type="text" class="form-control" id="depositcurrency"
                                            value={depositcurrency} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6">
                                        <label for="pips">Stop Loss(pips)</label>
                                        <input type="number" class="form-control" id="pips"
                                            value={pips} onChange={(e) => setpips(parseFloat(e.target.value))} />
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="accountbalance">Account Balance</label>
                                        <input type="number" class="form-control" id="accountbalance"
                                            value={accountbalance} onChange={(e) => setaccountbalance(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6">
                                        <label for="pipssize">GBPUSD 1 pip size</label>
                                        <input type="number" class="form-control" id="pipssize"
                                            value={pipssize} />
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="risk">Risk in %</label>
                                        <input type="number" class="form-control" id="risk"
                                            value={risk} onChange={(e) => setrisk(parseFloat(e.target.value))} />
                                        {validationrisk && <div class="validation-bar">*should be 0-100</div>}
                                    </div>
                                </div>
                                <button class="btn btn-primary" type="submit">Calculate</button>
                            </form>
                        </div>
                        <div class="col-xl-6 blue-box d-flex flex-column justify-content-center pl-5  ">

                            <div >
                                <p class="mb-0">Money at Risk</p><h5 class="mb-0"> {parseFloat((resultmoney).toFixed(2)).toLocaleString('en')}</h5>
                                {/* <p class="mb-0">USD</p> */}
                                <div class="border-center"></div>
                                <div class="d-flex justify-content-between">
                                    <div class="mr-5">
                                        <p class="mb-0">Units </p><h5 class="mb-0">{parseFloat((resultunits).toFixed(2)).toLocaleString('en')} </h5>
                                        <p class="mb-0">Trade size</p>
                                    </div>
                                    <div>
                                        <p class="mb-0">Lots </p><h5 class="mb-0"> {(resultlots).toFixed(5)}</h5>
                                        <p class="mb-0">Trade size</p>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <section class="defination">
            <div class ="container">
            <div class ="row">
            <div class ="col-xl-12">
            <div class ="defination-box">
            <h2>Definition</h2>
            <p>The Lot is the Forex metric that indicates the dimension of a trade.It can be considered a unit of measurement.</p>
            <b> The Standard Lot includes 100,000 units expressed in the base currency.</b>
            <p>
            Today it’s possible to trade with fractional lot sizes:
            Mini lots - 0.1 of a standard lot or 10,000 units
            Micro-lots - 0.01 of a standard lot or 1,000
            Nano-lots  - 0.001 of a standard lot or 100
            </p>
            <p>  Trading with fractional lot sizes is important for traders that have a small capital and allows them to manage better the risks associated with Forex trading.</p>
            <h4>To retain</h4>
            <ul>
            <li> Professional traders do not risk more than 2% of their capital on a trade</li>
            <li>The Position Size Calculator, also called the Lot Size Calculator, allows you to calculate the capital you risk on a trade expressed in percentage or the currency of your trading account.And indicates the dimension of the trade in lots in order to respect your risk strategy.</li>

            </ul>
            </div>


            <div class ="defination-box">
            <h2>    Terms of the Position Size Calculator/ Lot Size Calculator </h2>
            <h4>Inputs</h4>
            <ul >
            <li >
            <b> Currency pair: </b>Indicate the pair you want to trade. For example GBPUSD.
            </li>
            <li >
            <b> Deposit currency:</b>  Choose the currency of your trading account. For example USD.
            </li>
            <li >
            <b>Stop loss (pips):  </b>  Assess your risk and decide how many pips you want to risk on the trade. For example, you want to risk 100 pips.
            </li>
            <li >
            <b> Account balance:  </b>  The total amount of money that you have in your account. For example 5,000 USD.
            </li>
            <li >
            <b>Risk:   </b> Insert either a percentage of your capital that you want to risk or the exact amount in currency. For example 1.5 % of the capital.
            </li>
            </ul>
            <h4>Result</h4>

            <ul >
            <li >
            <b>The Position Size Calculator will indicate to you the dimension in lots for your trade.</b>
            </li>
            </ul>
            <h4>Example</h4>
            <p>Let’s say you want to place an order on GBPUSD, your trading account currency is USD. You want to place a stop loss in pips of 100 on this order. You have $5,000 on your account and want to assume a risk of 1.5%. The calculator will indicate that your order size should be 0.075 lots and you assume a risk of $75 on this position.</p>
            </div>
            </div>
            </div>

        </div>

            </section >
        </div >



    )
}
