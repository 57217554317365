import React, { Component } from 'react';
import baseURL from './baseurl';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts'

export default class PerformanceGraph extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        expected: [],
        actual: [],
        buttoncss: true,
        showexpected: true,
        ROIgraph: false,
        CROIgraph: true,
        RTCRgraph: false,
        RTCRdata: [],
        showprofitbutton: true,
        graphprofitbuttoncss: true,
        graphbuttoncss: true,
        from: '',
        to: '',
        defaultvalue: true,
        startdate: '',
        exposed: false,
        showactualinbox: true
    }
    oneweek = async (e) => {
        e.preventDefault()
        var weeklydate = new Date();
        var todayd = ("0" + (weeklydate.getDate())).slice(-2)
        var todaym = ("0" + (weeklydate.getMonth() + 1)).slice(-2)
        var todayy = weeklydate.getFullYear()
        var today = `${todaym}/${todayd}/${todayy}`

        weeklydate.setDate(weeklydate.getDate() - 6)
        var bsd = ("0" + (weeklydate.getDate())).slice(-2)
        var bsm = ("0" + (weeklydate.getMonth() + 1)).slice(-2)
        var bsy = weeklydate.getFullYear()
        var beforeseven = `${bsm}/${bsd}/${bsy}`
        await this.setState({
            defaultvalue: false,
            from: beforeseven,
            to: today
        })
        this.componentDidMount()
    }
    onemonth = async (e) => {
        e.preventDefault()
        var monthdate = new Date()
        var lastd = ("0" + (monthdate.getDate())).slice(-2)
        var lastm = ("0" + (monthdate.getMonth() + 1)).slice(-2)
        var lasty = monthdate.getFullYear()
        var lastdate = `${lastm}/${lastd}/${lasty}`
        monthdate.setMonth(monthdate.getMonth() - 1)
        var firstd = ("0" + (monthdate.getDate())).slice(-2)
        var firstm = ("0" + (monthdate.getMonth() + 1)).slice(-2)
        var firsty = monthdate.getFullYear()
        var firstdate = `${firstm}/${firstd}/${firsty}`
        await this.setState({
            defaultvalue: false,
            from: firstdate,
            to: lastdate
        })
        this.componentDidMount()
    }
    threemonth = async (e) => {
        e.preventDefault()
        var tmonthdate = new Date()
        var thld = ("0" + (tmonthdate.getDate())).slice(-2)
        var thlm = ("0" + (tmonthdate.getMonth() + 1)).slice(-2)
        var thly = tmonthdate.getFullYear()
        var tlastdate = `${thlm}/${thld}/${thly}`
        tmonthdate.setMonth(tmonthdate.getMonth() - 3)
        var thfd = ("0" + (tmonthdate.getDate())).slice(-2)
        var thfm = ("0" + (tmonthdate.getMonth() + 1)).slice(-2)
        var thfy = tmonthdate.getFullYear()
        var tfirstdate = `${thfm}/${thfd}/${thfy}`
        await this.setState({
            defaultvalue: false,
            from: tfirstdate,
            to: tlastdate
        })
        this.componentDidMount()
    }

    sixmonth = async (e) => {
        e.preventDefault()
        var smonthdate = new Date()
        var sild = ("0" + (smonthdate.getDate())).slice(-2)
        var silm = ("0" + (smonthdate.getMonth() + 1)).slice(-2)
        var sily = smonthdate.getFullYear()
        var slastdate = `${silm}/${sild}/${sily}`
       
        smonthdate.setMonth(smonthdate.getMonth() - 6)
        var sifd = ("0" + (smonthdate.getDate())).slice(-2)
        var sifm = ("0" + (smonthdate.getMonth() + 1)).slice(-2)
        var sify = smonthdate.getFullYear()
        var sfirstdate = `${sifm}/${sifd}/${sify}`
      
        await this.setState({
            defaultvalue: false,
            from: sfirstdate,
            to: slastdate
        })
        this.componentDidMount()

    }
    oneyear = async (e) => {
        e.preventDefault()
        var oyeardate = new Date()
        var oyld = ("0" + (oyeardate.getDate())).slice(-2)
        var oylm = ("0" + (oyeardate.getMonth() + 1)).slice(-2)
        var oyly = oyeardate.getFullYear()
        var oylastdate = `${oylm}/${oyld}/${oyly}`
        oyeardate.setFullYear(oyeardate.getFullYear() - 1)

        var oyfd = ("0" + (oyeardate.getDate())).slice(-2)
        var oyfm = ("0" + (oyeardate.getMonth() + 1)).slice(-2)
        var oyfy = oyeardate.getFullYear()
        var oyfirstdate = `${oyfm}/${oyfd}/${oyfy}`
        await this.setState({
            defaultvalue: false,
            from: oyfirstdate,
            to: oylastdate
        })
        this.componentDidMount()
    }
    alldata = async (e) => {
        e.preventDefault()
        await this.setState({ defaultvalue: true })
        this.componentDidMount()
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            if (this.state.showexpected) {
                return (
                    <div className="custom-tooltip" style={{ padding: "8px", borderRadius: "4px", backgroundColor: "white", boxShadow: '0px 10px 50px #0082f21a' }}>
                        <h5 style={{ color: "#888f97", fontSize: "16px", marginBottom: "-6px" }}>Projected: {`${payload[0].value}%`}</h5>
                        {this.state.showactualinbox && <h5 style={{ color: "#0970C8", marginBottom: "-6px", fontSize: "16px", fontWeight: "600" }}> {this.state.exposed ? "Exposed" : "Actual"}: {`${payload[1].value}%`} </h5>}
                        <div className="label" style={{ fontSize: "12dpx", marginTop: "3px" }}>{`${label}`}</div>
                    </div>
                );
            } else {
                return (
                    <div className="custom-tooltip" style={{ padding: "8px", borderRadius: "4px", backgroundColor: "white", boxShadow: '0px 10px 50px #0082f21a' }}>
                        {this.state.showactualinbox && <h5 style={{ color: "#0970C8", marginBottom: "-6px", fontSize: "16px", fontWeight: "600" }}> {this.state.exposed ? "Exposed" : "Actual"}: {`${payload[0].value}%`} </h5>}
                        <div className="label" style={{ fontSize: "12dpx", marginTop: "3px" }}>{`${label}`}</div>
                    </div>
                );
            }

        }

        return null;
    };
    CustomTooltipRTCR = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            if (this.state.showexpected) {
                return (
                    <div className="custom-tooltip" style={{ padding: "8px", borderRadius: "4px", backgroundColor: "white", boxShadow: '0px 10px 50px #0082f21a' }}>
                        <h5 style={{ color: "#888f97", fontSize: "16px", marginBottom: "-6px" }}>Projected: {`${payload[0].value}%`}</h5>
                        {this.state.showactualinbox && <h5 style={{ color: "#0970C8", marginBottom: "-6px", fontSize: "16px", fontWeight: "600" }}>Actual: {`${payload[1].value}%`}</h5>}
                        <div className="label" style={{ fontSize: "12dpx", marginTop: "3px" }}>{`${label}`}</div>
                    </div>
                );
            } else {
                return (
                    <div className="custom-tooltip" style={{ padding: "8px", borderRadius: "4px", backgroundColor: "white", boxShadow: '0px 10px 50px #0082f21a' }}>
                        {this.state.showactualinbox && <h5 style={{ color: "#0970C8", marginBottom: "-6px", fontSize: "16px", fontWeight: "600" }}>Actual: {`${payload[0].value}%`}</h5>}
                        <div className="label" style={{ fontSize: "12dpx", marginTop: "3px" }}>{`${label}`}</div>
                    </div>
                )

            }

        }

        return null;
    };

    async componentDidMount() {
        var date = new Date()
        var ty = date.getFullYear()
        var tm = ("0" + (date.getMonth() + 1)).slice(-2)
        var td = ("0" + date.getDate()).slice(-2)
        var todaydate = `${tm}/${td}/${ty}`
        if (this.state.defaultvalue) {
            await baseURL.get('/get-data/startingdate').then(async res=>{
                await this.setState({ from: res.data.startdate, to: todaydate })
             })
        }
       
        baseURL.get(`get-data/graph-data?from=${this.state.from}&to=${this.state.to}`)
            .then(async res => {
                if (res.data.actual[0].ROI === undefined) {
                    await this.setState({ showactualinbox: false, showexpected: true })
                }
                var array = []
                for (var a = 0; a < res.data.actual.length; a++) {
                    await array.push({
                        date: res.data.actual[a].date,
                        actual_ROI: parseFloat(res.data.actual[a].ROI),
                        actual_CROI: parseFloat(res.data.actual[a].CROI),
                        projected_ROI: parseFloat(res.data.actual[a].proROI),
                        projected_CROI: parseFloat(res.data.actual[a].proCROI),
                        expoROI: parseFloat(res.data.actual[a].expoROI),
                        expoCROI: parseFloat(res.data.actual[a].expoCROI),
                    })
                }
                var rtcrarr = []
                for (var b = 0; b < res.data.RTCRarr.length; b++) {
                    await rtcrarr.push({
                        date: res.data.RTCRarr[b].date,
                        RTCR: parseFloat(res.data.RTCRarr[b].RTCR),
                        proRTCR: res.data.RTCRarr[b].proRTCR
                    })
                }
                this.setState({ actual: array, RTCRdata: rtcrarr })

            })
    }
    render() {
        return (
            <>
                <div class="container">
                    <div class="row d-flex justify-content-center">

                        <div class="col-xl-9">
                            <div style={{ backgroundColor: "#ebf5fe", borderRadius: "5px", padding: "2% 5%" }}>
                                <div class="my-3  d-flex justify-content-end">
                                    <a class={this.state.graphbuttoncss ? "btn-line mx-3" : "btn-noline mx-3"} onClick={() => this.setState({ showprofitbutton: true, graphbuttoncss: true, CROIgraph: true, graphprofitbuttoncss: true, RTCRgraph: false })}>Profit</a>
                                    <a class={this.state.graphbuttoncss ? "btn-noline" : "btn-line"} onClick={() => this.setState({
                                        RTCRgraph: true, ROIgraph: false, CROIgraph: false, showprofitbutton: false, graphbuttoncss: false
                                    })}>Risk</a>
                                </div>

                                <div class="graph-bg p-4">
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="graph-heading">
                                            {/* <h4>5.5%</h4>
                                        <p>Daily Return</p> */}
                                        </div>
                                        {this.state.showprofitbutton &&
                                            <div>
                                                <a class={this.state.graphprofitbuttoncss ? "btn-graph1 mr-xl-3" : "btn btn-graph mr-xl-3"} onClick={() => this.setState({ ROIgraph: true, CROIgraph: false, RTCRgraph: false, graphprofitbuttoncss: false })}>Daily</a>
                                                <a class={this.state.graphprofitbuttoncss ? "btn btn-graph ml-xl-3" : "btn-graph1 ml-xl-3"} onClick={() => this.setState({ CROIgraph: true, ROIgraph: false, RTCRgraph: false, graphprofitbuttoncss: true })}>Cumulative</a>
                                            </div>}
                                    </div>
                                    <div class="mt-5">
                                        {this.state.CROIgraph &&
                                            <ResponsiveContainer width="100%" aspect={2} >
                                                <LineChart data={this.state.actual} >
                                                    {/* <XAxis hide={true} dataKey="date" tick={false} interval={'preserveStartEnd'} */}
                                                    <XAxis dataKey="date" interval={'preserveStartEnd'}
                                                    />
                                                    <YAxis type="number" tickFormatter={(tick) => { return `${(tick).toFixed(2)}%` }}  />
                                                    {this.state.showexpected && <Line type="monotone" dataKey="projected_CROI"
                                                        isAnimationActive={false} dot={false} strokeWidth={3} stroke="#888f97"
                                                        activeDot={{ stroke: 'rgb(136, 143, 151, 0.3)', strokeWidth: 7 }} />}
                                                    <Line type="monotone" data={this.state.actual} dataKey={this.state.exposed ? "expoCROI" : "actual_CROI"} stroke="#0970C8"
                                                        isAnimationActive={false} dot={false} strokeWidth={3}
                                                        activeDot={{ stroke: 'rgba(9, 112, 200, 0.3)', strokeWidth: 7 }} />
                                                    <Tooltip content={<this.CustomTooltip />} position={{ x: 10, y: -50 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                        {this.state.ROIgraph &&
                                            <ResponsiveContainer width="100%" aspect={2} >
                                                <LineChart data={this.state.actual} >
                                                    <XAxis dataKey="date"  interval={'preserveStartEnd'} />
                                                    {/* <XAxis hide={true} dataKey="date" tick={false} interval={'preserveStartEnd'} /> */}
                                                    <YAxis type="number" tickFormatter={(tick) => { return `${(tick).toFixed(2)}%` }} />
                                                    {/* <YAxis hide={true} type="number" tickFormatter={(tick) => { return `${(tick).toFixed(1)}%` }} /> */}
                                                    {this.state.showexpected && <Line type="monotone" dataKey="projected_ROI"
                                                        isAnimationActive={false} dot={false} strokeWidth={3} stroke="#888f97"
                                                        activeDot={{ stroke: 'rgb(136, 143, 151, 0.3)', strokeWidth: 7 }} />}
                                                    <Line type="monotone" data={this.state.actual} dataKey={this.state.exposed ? "expoROI" : "actual_ROI"}
                                                        isAnimationActive={false} dot={false} strokeWidth={3} stroke="#0970C8"
                                                        activeDot={{ stroke: 'rgba(9, 112, 200, 0.3)', strokeWidth: 7 }} />
                                                    <Tooltip content={<this.CustomTooltip />} position={{ x: 10, y: -50 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                        {this.state.RTCRgraph &&
                                            <ResponsiveContainer width="100%" aspect={2} >
                                                <LineChart data={this.state.RTCRdata}>
                                                    {/* <XAxis hide={true} dataKey="date" tick={false} interval={'preserveStartEnd'} /> */}
                                                    <XAxis dataKey="date" interval={'preserveStartEnd'} />
                                                    {/* <YAxis hide={true} type="number" tickFormatter={(tick) => { return `${(tick).toFixed(2)}%` }} /> */}
                                                    <YAxis type="number" tickFormatter={(tick) => { return `${(tick).toFixed(2)}%` }} />
                                                    {this.state.showexpected && <Line type="monotone" dot={false} dataKey="proRTCR" strokeWidth={3} stroke='#888f97'
                                                        isAnimationActive={false} activeDot={{ stroke: 'rgb(136, 143, 151, 0.3)', strokeWidth: 7 }} />}                                                  <Tooltip content={<this.CustomTooltipRTCR />} position={{ x: 10, y: -25 }} />
                                                    <Line type="monotone" dot={false} data={this.state.RTCRdata} dataKey="RTCR" strokeWidth={3} stroke="#0970C8"
                                                        isAnimationActive={false} activeDot={{ stroke: 'rgba(9, 112, 200, 0.3)', strokeWidth: 7 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>


                                    {this.state.RTCRgraph ? <div></div> : <div class="rotate-button" >
                                        {/* <button  onClick={()=>this.setState({exposed:false})}>total</button> */}
                                        <a class={this.state.exposed ? "btn-graph mr-lg-4" : "btn-graph1 btn btn-outline-primary mr-lg-4"} onClick={() => this.setState({ exposed: true })}>Exposed Capital</a>
                                        <a class={this.state.exposed ? "btn-graph1 btn btn-outline-primary " : "btn-graph "} style={{ cursor: "pointer" }} onClick={() => this.setState({ exposed: false })}>Total Capital</a>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap mt-3">
                                <div class="">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link mr-2 btn btn-outline-primary" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.oneweek(e)}>1W</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link mr-2 btn btn-outline-primary" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.onemonth(e)}>1M</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link mr-2 btn btn-outline-primary" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={(e) => this.threemonth(e)}>3M</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link btn mr-2 btn-outline-primary" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={(e) => this.sixmonth(e)}>6M</a>
                                        </li>
                                        <li class="nav-item " role="presentation">
                                            <a class="nav-link mr-2 btn btn-outline-primary " id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={(e) => this.oneyear(e)}>1Y</a>
                                        </li>
                                        <li class="nav-item " role="presentation">
                                            <a class="nav-link mr-2 btn btn-outline-primary active" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={(e) => this.alldata(e)}>All</a>
                                        </li>
                                    </ul>

                                </div>
                                <a class="btn-graph mb-4 float-right" onClick={() => { this.setState({ showexpected: !this.state.showexpected }); this.componentDidMount() }}>{this.state.showexpected ? "Actual" : "Forecast"}</a>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
