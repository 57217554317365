import React, { Component } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import Benefit from './components/benefits';
import Subscription from './components/subscription';

ReactGA.initialize('UA-208545193-1')
export default class About extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname)
        Aos.init({
            duration: 2000
        })
    }
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="make money with forex " />
                    <meta name="description" content="Fort Knox Global offers an opportunity to make money with Forex for any investor interested. Copy our signals or adhere to one of our PAMM accounts" />
                    <title>Fort Knox - Make Money with Forex Hassle-Free</title>
                </Helmet>
                <Navbar />
                <section class="Performance-subheading">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h4>About</h4>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section>
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-6">
                                <div class="support-outside text-center">
                                    <p>Not available to US residents. </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section class="about">
                    <div class="container">
                        <div class="row d-flex justify-content-center">

                            {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"  >
                                <div class="about-img">
                                    <img src="assets/about.svg" class="img-fluid" />
                                </div>
                            </div> */}

                            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12"  >



                                <div class="right-about">
                                    <h2 class="section-heading">About Fort Knox Global</h2>
                                    <h6 class="section-subheading">Innovative ideas for better returns</h6>
                                    <p>Fort Knox Global is a company that seeks to grow value for its shareholders by engaging in innovative financial solutions and strategies.

                                    </p>
                                    <p>We scout the globe to seek investment opportunities in both conventional and unconventional markets, using fundamentals and sound judgment with proven techniques that lead to good returns.</p>
                                </div>


                            </div>

                        </div>
                    </div>
                </section>
                <section class="misson">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            {/* <div class="col-xl-5 d-flex align-items-stretch justify-content-end " >
                                <div class="average text-center">
                                    <h6>30%</h6>
                                    <p>Average returns in the
                                        last 10 years</p>
                                </div>

                            </div> */}

                            <div class="col-xl-5 pt-3 text-center">
                                <div class="vison ">

                                    <h4 class="text-center">Vision</h4>
                                    {/* <p>To Create Wealth in the short, medium and long term for all Stakeholders in a minimal risk-free manner.</p> */}
                                    <p>To provide innovative solutions to everyday investments.</p>
                                </div>

                                <div class="vison ">

                                    <h4 class="text-center">Mission</h4>
                                    {/* <p>We seek to assist our stakeholders actualize their dreams at creating and preserving additional wealth .</p> */}
                                    <p>Our mission is to redefine wealth and asset management</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <section class=" mb-5 benefits-section  m-0">
                    <div class="container">
                        <div class="row pb-4">
                            <div class="col-xl-12 ">
                                <div class="text-center">
                                    <h2 class="section-heading ">Forex Investment Strategy</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-xl-12">
                                <div class="p-4">
                                    {/* <p>We deliberately concentrate all our trading efforts on this pair as we have an in-depth understanding of the two countries' economies and the way they are affected by geopolitical events and intrinsic economic data</p>
                                    <p>One of the key pillars of this strategy is the compounding effect. Meaning small monthly gains add up to generate significant profits by the end of the year. Plus, the risk associated with the trading activity diminishes in time and your initial capital is less and less exposed, until the entire exposure of the open trades runs on profits. </p>
                                    <p>As per the trading strategy, the risk of capital is maximum 11.2% at any given moment. Targeting a 7.5% profit monthly which is 90% annually, after the first profitable month your risk exposure moves on the past month profits. As such your capital is less exposed to risk.</p>
                                    <p>Our service provides Forex trading signals that will be automatically copied and executed into our clients’ trading accounts opened with their brokers.</p> */}
                                    <p>We have an in-depth understanding of currencies and the economies of a few countries and the way they are affected by geopolitical events and intrinsic economic data.</p>
                                    <p>We have a proven proprietary trading strategy. One of the key pillars of this strategy is the use of compounding effect of gains to diminish capital risk over time. </p>
                                    <p>We have a low risk to Capital ratio.</p>
                                    <p>We offer PAMM accounts to interested clients.</p>
                                </div>
                            </div>
                            {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"  >
                                <div class="p-4">
                                    <img src="assets/about1.svg" class="img-fluid" />
                                </div>
                            </div> */}

                        </div>


                    </div>
                </section>
                <section class="benefits-section bg-white m-0">
                   <Benefit />
                </section>
                <section id="pricing" class="pricing">
                 <Subscription />
                </section>
                <Footer />
            </div>
        )
    }
}
