import React, { Component } from 'react'
import baseurl from './baseurl'
import {Link} from 'react-router-dom'

export default class Homeperformance extends Component {
    state = {
        data: [],
        initialcapital:0,
        CROI:0
    }
    async componentDidMount() {
        await baseurl.get('/get-data/myfxbook-data')
        .then(async res => {
            await  this.setState({ data: res.data,initialcapital:(res.data.initialcapital)?.toLocaleString('en-US') })
        })
        await baseurl.get('/get-data/startingdate').then(async res=>{
            var date = new Date()
            var ty = date.getFullYear()
            var tm = ("0" + (date.getMonth() + 1)).slice(-2)
            var td = ("0" + date.getDate()).slice(-2)
            var todaydate = `${tm}/${td}/${ty}`
        
            await baseurl.get(`get-data/graph-data?from=${res.data.startdate}&to=${todaydate}`)
            .then(async respo => {
               this.setState({CROI:respo.data?.actual[respo.data.actual?.length-1]?.CROI || 0 }) 
            })
         })
    }
    render() {
        return (
            <>
                <section class="performance-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h2 class="section-heading">Real Time Performance</h2>
                                    <h6 class="section-subheading">Check our numbers, they are our best ambassadors</h6>

                                </div>
                            </div>
                        </div>
                        <div class="row card-section">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Return on Investment 
                                            </p>
                                        </div>
                                        <h2>{this.state.CROI}%</h2>
                                     
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Total profit for the trading activity expressed as percentage of the starting capital</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Initial deposit 
                                            </p>
                                        </div>
                                        {/* <h2>${this.state.data.initialcapital}</h2> */}
                                        <h2>$ {this.state.initialcapital}</h2> 
                                        <p>USD</p>   
                                       
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Amount of money invested initially </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Risk to Capital Ratio
                                            </p>
                                        </div>
                                        <h2>{(this.state.data.RTCR)}% </h2>
                                      
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Percentage of the capital risked on a trade as average</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Number of clients
                                            </p>
                                        </div>
                                        <h2>0</h2>
                                      
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Clients that have already chosen our services</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Trading experience
                                            </p>
                                        </div>
                                        <h2>10+ </h2>
                                        <p>Years</p>
                                       
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Number of years dedicated to professional Forex trading</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                                Current Balance
                                            </p>
                                        </div>
                                        <h2>${this.state.data.balance}</h2>
                                        <p>Performance over 3 year's</p>
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>based on Start Date </p>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                            Total trades to date
                                            </p>
                                        </div>
                                        <h2>{this.state.data.totaltradestodate}</h2>
                                      
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Total number of trades placed on the market since the account was launched</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6"  >
                                <div class="flip-container">
                                    <div class=" flip-container-front">
                                        <div class="flex">
                                            <p class="top-left " >
                                                profit
                                            </p>
                                        </div>
                                        <h2>${this.state.data.profit}</h2>
                                        <p>Performance over 3 year's</p>
                                    </div>

                                    <div class=" flip-container-back d-flex align-items-center justify-content-center">

                                        <p>Performance over 3 year's</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="flex">
                                  <Link to="/performance" onClick={()=>window.location.href=('/performance')}>  <a href="" class="btn btn-primary ">Check trading performance</a></Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            </>
        )
    }
}
