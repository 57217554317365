import React, { useEffect } from 'react'
import Navbar from './navbar';
import Footer from './footer'
import { Link, useHistory } from 'react-router-dom';
import Aos from 'aos';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga'
import Subscription from './components/subscription';

ReactGA.initialize('UA-208545193-1')

export default function Products() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
        Aos.init({
            duration: 2000
        })
    })
    const history = useHistory()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="Best Forex signals,forex pamm account,copy-trading " />
                <meta name="description" content="Get higher profits from your trading activity with one of the best Forex signals and professionally managed PAMM accounts" />
                <title>Best Forex Signals to Improve Your Trading Returns</title>
            </Helmet>
            <Navbar />
            <section class="Performance-subheading">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="text-center">
                                <h4>Product</h4>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-xl-6">
                            <div class="support-outside text-center">
                                <p>Not available to US residents. </p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <section id="pricing" class="pricing">
                <Subscription />
            </section>

            {/* <section class="services my-5">
                <div class="container">

                    <div class="row">
                        <div class="col-xl-12 ">
                            <div class="text-center  ">
                                <h6 class="section-subheading ">Choose the product for you & get started</h6>

                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">

                        <div class="col-xl-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear" >
                            <div class="flex primary-card-shadow">
                                <img src="assets/Icn-10.svg" class="img-fluid" alt="icon" />
                                <Link to="/getstarted/pamm-account" style={{ textDecoration: "none" }}>  <div class="chooseproduct">
                                    <h5 class="text-primary">PAMM Account</h5>
                                    <p>A PAMM Account, allows an investor to invest a percentage of his investment Capital and
                                        earn income by relying on the expertise of the Trading Manager.</p>
                                </div></Link>

                            </div>
                        </div> */}

                        {/* <div class="col-xl-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                            <div class="flex primary-card-shadow">
                                <img src="assets/Icn-11.svg" class="img-fluid" alt="icon" />
                                <Link to="/getstarted/signal-copy-trading" style={{ textDecoration: "none" }}>
                                    <div class="chooseproduct">
                                        <h5 class="text-primary">Signal Copy Trading</h5>
                                        <p>This flexible trading option allows our investors to automatically copy positions opened
                                            and managed by our Trading Managers on their own funds.</p>
                                    </div>
                                </Link>

                            </div>

                        </div> */}

                    {/* </div> */}
                {/* </div> */}
            {/* </section> */}
            <Footer />
        </div>
    )
}
