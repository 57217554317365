import React, { Component } from 'react'
import baseURL from './baseurl'
import { Helmet } from 'react-helmet'

export default class Subscriptionroi extends Component {
    state = {
        subscription: 'Gold - $125 per month',
        capitaldeposit: 10000,
        subscriptionroi: 0,
        monthlyroi: 0,
        defaultvalue: true
    }
    componentDidMount() {
        if (this.state.defaultvalue) {
            baseURL.get('/get-data/calculator-data').then(async res => {
                await this.setState({ monthlyroi: parseFloat('3.75') })
                var monthlyprofit = (this.state.capitaldeposit * parseFloat('3.75')) / 100
                await this.setState({ subscriptionroi: (monthlyprofit - 125) })
            })
        }

    }
    submitdata = async (e) => {
        e.preventDefault()
        if (this.state.subscription == 'Gold - $125 per month') {
            var monthlyprofit = (this.state.capitaldeposit * this.state.monthlyroi) / 100
            await this.setState({ subscriptionroi: (monthlyprofit - 125) })
        }
        this.forceUpdate()
    }
    render() {
        return (
            <div class="calculator">
            <div class="container">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Forex compounding calculator" />
                    <meta name="description" content="Forex Compounding Calculator helps to forecast the potential profits obtained from a Forex investment using the compound interest formula" />
                    <title>Forex Compounding Calculator - Forecast your future gains </title>
                </Helmet>
                <div class="row d-flex">
                    <div class="col-xl-6">
                        <div class="calculator-form">
                        <h4>Please enter the following information</h4>
                        <form onSubmit={(e) => this.submitdata(e)}>
                            <div class="form-group">
                                <label for="subscription">Subscription</label>
                                <select class="form-control" id="subscription"
                                    onChange={(e) => this.setState({ subscription: e.target.value })}>
                                    <option>Gold - $125 per month</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="capitaldeposit">Capital Deposit</label>
                                <input type="number" class="form-control" id="capitaldeposit"
                                    value={this.state.capitaldeposit} onChange={(e) => this.setState({ capitaldeposit: parseFloat(e.target.value) })} />
                            </div>
                            <div class="mt-5 d-flex">
                                <p style={{ fontSize: "20px" }}>Monthly ROI: </p>
                                <h5 style={{marginLeft:"10px",color:"#0082F2"}}>{(this.state.monthlyroi).toFixed(2)}%</h5>
                            </div>
                            <button class="btn btn-primary" type="submit">Calculate</button>
                        </form>
                        </div>
                    </div>
                    <div class="col-xl-6 blue-box d-flex pl-5 align-items-center">
                        <div class="">
                            {/* <p class="mb-0">monthly Payment</p><h5 class="mb-0">${(this.state.monthlypayments).toFixed(2)}</h5> */}
                            <p class="mb-0">Subscription ROI</p>
                            <h5 class="mb-0">${parseFloat((this.state.subscriptionroi).toFixed(2)).toLocaleString('en')}</h5>
                            <p class="mb-0">(Monthly Profit - Monthly Subscription)</p>
                            <div class="border-center"></div>
                            <p class="mb-0" style={{ fontSize: "14px", maxWidth: "350px" }}>Monthly profit is based on current real-time
                                performance and is subject to change</p>
                        </div>
                    </div>

                </div>
            </div></div>
        )
    }
}
