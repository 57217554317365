import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import { Link } from 'react-router-dom'
import Aos from 'aos'


export default function MetatraderMacOS() {
    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    })
    return (
        <div>
            <Navbar />
            <section class="Performance-subheading">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="text-center">
                                <h4>Get Started</h4>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-xl-12 mt-4">
                            <h5>Download Parallels for Mac OS</h5>
                            <div>Most brokers only support copy trading on the Metatrader 4 platform via the Windows Operating System. So if you are using a Mac computer you will need to download the Windows Metatrader 4 version on your Mac. You can do this by creating a virtual machine that allows you to run the Windows Operating System and download Windows Applications simultaneously on your Mac.
                            </div>

                            <div class="mt-4 ">

                                <section id="steps" class="steps">
                                    <div class="pl-1">

                                        <div class="row no-gutters aos-init aos-animate" data-aos="fade-up">
                                            <div class="col-xl-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">

                                                <div class=" px-5">


                                                    <div class="primary-card-shadow1 pb-2 mb-4">
                                                        <h5 class=" heading mt-4"> <span >1</span>Download Parallels for Mac.</h5>

                                                        <img src="/assets/26.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                                        <p class="mx-5"> Follow these instructions <a href="https://www.imore.com/how-run-windows-10-your-mac-using-parallels-desktop-13"> 
                                                            https://www.imore.com/how-run-windows-10-your-mac-using-parallels-desktop-13 </a>
                                                            Please note you will have to purchase Parallels and also purchase Windows to run this on your machine.</p>

                                                    </div>

                                                    <div class="primary-card-shadow1 pb-2 mb-4">
                                                        <h5 class=" heading mt-4"> <span >2</span> Open Parallels Desktop</h5>
                                                        <img src="/assets/27.png" class="img-fluid  mx-5 mb-3" width="400px"alt="image" />
                                                        <p class="mx-5">Scroll down and search for the “Download” button to be able to install the MetaTrader 4 platform on your desktop.</p>
                                                        {/* <p> Click the button, download the file, execute it and follow the steps to install the trading platform</p> */}

                                                        {/* <p class="mx-5">If you encounter issues check your broker Learn or Education center. They usually have a tutorial detailing the procedure of installing the platform on all devices. </p>

                                    <p class="mx-5">In our case Oanda does not have a “Download” button from MetaTrader, you have to install it from your account. Here is the process: <a href=" https://www.youtube.com/watch?v=OE-XH4d6-kg">https://www.youtube.com/watch?v=OE-XH4d6-kg</a></p> */}


                                                    </div>

                                                    <div class="primary-card-shadow1 pb-2 mb-4">
                                                        <h5 class=" heading mt-4"> <span >3</span>Open broker site</h5>
                                                        <p class="mx-5">Navigate to your broker site to download the Metatrader 4 platform within your Windows view</p>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="steps" class="steps">
                <div class="container">

                    <div class="row no-gutters aos-init aos-animate" data-aos="fade-up">
                        <div class="col-xl-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">

                            <div class=" px-5">

                                <div>
                                    <h5 class="  my-4"> Download the MetaTrader Platform</h5>

                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >1</span>Platforms</h5>

                                    <img src="/assets/9.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">To download the trading platform, you should access the “Platforms” section of your broker’s site. Usually, it is on the main menu.</p>

                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >2</span>Download</h5>

                                    <p class="mx-5">Scroll down and search for the “Download” button to be able to install the MetaTrader 4 platform on your desktop.</p>
                                    {/* <p> Click the button, download the file, execute it and follow the steps to install the trading platform</p> */}

                                    {/* <p class="mx-5">If you encounter issues check your broker Learn or Education center. They usually have a tutorial detailing the procedure of installing the platform on all devices. </p>

                                    <p class="mx-5">In our case Oanda does not have a “Download” button from MetaTrader, you have to install it from your account. Here is the process: <a href=" https://www.youtube.com/watch?v=OE-XH4d6-kg">https://www.youtube.com/watch?v=OE-XH4d6-kg</a></p> */}


                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >3</span>Connect the MetaTrader4</h5>

                                    <img src="/assets/10.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Connect the MetaTrader4 to a Signal Provider. Open the platform, go to the “Tools” tab, then select “Options”.</p>

                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >4</span>Community</h5>

                                    <img src="/assets/11.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Inside the “Options” window, select the “Community” tab, in the right upper corner and log in with your MQL5 credentials if you have an account or register for an account.</p>

                                </div>


                            </div>
                            <div class="mb-5 text-center"><Link to="/get-started/copy-trading/mql" ><button class="btn btn-primary">Next</button></Link></div>
                        </div>


                    </div>

                </div>
            </section>
            <Footer />

        </div>
    )
}
