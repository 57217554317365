import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'


export default class Drawdowncalculator extends React.Component {
    state = {
        startingbalance: 20000,
        losses: 10,
        losspertrade: 2,
        startingbalanceinput: 20000,
        lossesinput: 10,
        losspertradeinput: 2,
        endingbalance: 16341.46,
        totalloss: 18.29,
        arr: [],
        validationloss: false
    }

    submitvalue = async (e) => {
        e.preventDefault()
        if (this.state.losspertradeinput > 100 || this.state.losspertradeinput < 0) {
            this.setState({ validationloss: true })
        }
        else {
            await this.setState({
                validationloss: false,
                arr: [],
                startingbalance: parseFloat(this.state.startingbalanceinput),
                losses: parseFloat(this.state.lossesinput),
                losspertrade: parseFloat(this.state.losspertradeinput)
            })
            this.componentDidMount()
        }

    }
    async componentDidMount() {
        var starting = this.state.startingbalance
        var rate = this.state.losspertrade
        for (let i = 0; i < this.state.losses; i++) {
            var ending = starting - (starting * this.state.losspertrade) / 100
            rate = (((this.state.startingbalance - ending) * 100) / this.state.startingbalance)
            await this.state.arr.push(
                {
                    starting: starting,
                    rate: rate,
                    ending: ending
                }
            )
            starting = ending
            this.setState({ endingbalance: ending, totalloss: rate })


        }
    }
    render() {
        return (
            <div class="calculator">
            <div>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Forex Drawdown Calculator" />
                    <meta name="description" content="Forex Drawdown Calculator displays the maximum capital loss in the hypothesis of a number of consecutive losing trades when the market evolved against you" />
                    <title>Forex Drawdown Calculator - Maximum expected loss</title>
                </Helmet>
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-10">
                        <div class="calculator-box d-flex justify-content-between">

                            <div class="calculator-form">
                                <h4>Please enter the following information</h4>
                                <form onSubmit={(e) => this.submitvalue(e)}>
                                    <div class="form-group">
                                        <label for="startingcapital">Starting Balance</label>
                                        <input type="number" class="form-control" id="startingcapital"
                                            value={this.state.startingbalanceinput} onChange={(e) => this.setState({ startingbalanceinput: e.target.value })} />
                                    </div>
                                    <div class="form-group">
                                        <label for="losses">Consecutive losses</label>
                                        <input type="number" class="form-control" id="losses"
                                            value={this.state.lossesinput} onChange={(e) => this.setState({ lossesinput: e.target.value })} />
                                    </div>
                                    <div class="form-group">
                                        <label for="losspertrade">Loss % per trade</label>
                                        <input type="number" class="form-control" id="losspertrade"
                                            value={this.state.losspertradeinput} onChange={(e) => this.setState({ losspertradeinput: (e.target.value) })} />
                                        {this.state.validationloss && <div class="validation-bar">*should be 0-100</div>}
                                    </div>
                                    <button class="btn btn-primary" type="submit">Calculate</button>
                                </form>
                            </div>
                            <div class="blue-box   d-flex pl-5 align-items-center">
                                <div class="">
                                    <p class="mb-0">Ending balance</p><h5 class="mb-0"> {parseFloat((this.state.endingbalance).toFixed(2)).toLocaleString('en')}</h5>
                                    {/* <p class="mt-0">USD</p> */}
                                    <div class="border-center"></div>
                                    <p class="mb-0">Total loss </p><h5 class="mb-0">{(this.state.totalloss).toFixed(2)}%</h5>
                                    <p class="mt-0">Percent</p>
                                </div>
                            </div>
                        </div>
                        <section class="table-section">
                            <div class="container">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-xl-10 ">
                                        <div class="text-center">
                                            <table class="table table-striped">
                                                <thead>
                                                    <th>No.</th>
                                                    <th>Starting balance</th>
                                                    <th>Total Loss</th>
                                                    <th>Ending balance</th>
                                                </thead>
                                                <tbody>
                                                    {this.state.arr.map((data,i) => {

                                                        return (
                                                            <tr>
                                                                <td>{i+1}</td>
                                                                {Number.isInteger(data.starting) ? <td>{(data.starting).toLocaleString('en')}</td> : <td>{parseFloat((data.starting).toFixed(2)).toLocaleString('en')}</td>}
                                                                {Number.isInteger(data.rate) ? <td>{data.rate}%</td> : <td>{(data.rate).toFixed(2)}%</td>}
                                                                {Number.isInteger(data.ending) ? <td>{(data.ending).toLocaleString('en')}</td> : <td>{parseFloat((data.ending).toFixed(2)).toLocaleString('en')}</td>}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>            

                       <section class="defination">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="defination-box">
                                            <h2>Definition</h2>
                                            <p>  Drawdown is a key concept for assessing the risk of trading strategies. And even if Forex trading implies risks anyway, you should assume only calculated risks.
                                                Drawdown is the difference between the maximum balance level and the minimum balance level of a trading account, expressed in percentage or currency.
                                            </p>
                                            <h4>To retain</h4>

                                            <h4> Drawdown Formula</h4>

                                            <p>
                                                <b>   Drawdown = (maximum balance - minimum balance)/maximum balance x 100</b><br />

                                                Drawdown is also a criteria to compare two or more trading strategies. The highest return is not necessarily the indicator of a good strategy if it assumed risks too high.
                                                The Forex Drawdown Calculator is a critical tool to calculate the risks for your trading activity. It helps avoid reaching high levels of drawdown and eventually the risk of total loss.

                                            </p>
                                        </div>


                                        <div class="defination-box">
                                            <h2>  Terms of the Forex Drawdown Calculator</h2>
                            
                                            <ul >
                                                <h4>Inputs</h4>
                                                <li >
                                                    <b> Starting balance:</b>  The amount of money/capital that a trading account has at the beginning. For example $5,000.
                                                </li>
                                                <li >
                                                    <b>Consecutive loses: </b>    Insert the number of consecutive trades that registered a loss. For example 7 consecutive trades that closed on loss.
                                                </li>
                                                <li >
                                                    <b> Loss % per trade:</b>  Insert the percentage of capital lost per trade. For example 2%.
                                                </li>
                                            </ul>
                                            <h4>Result</h4>

                                            <ul >
                                                <li >
                                                    <b> The calculator will display the total loss from the starting balance in percentage and the new level of the account balance after losing X consecutive trades.</b>
                                                </li>
                                              
                                            </ul>
                                            <h4>Example</h4>
                                            <p> Let’s say you have an initial balance in your account of $5,000. Your trading activity registers 7 consecutive losses, each loss registering a loss of 2%. Your total loss will be of 13.2 % and the new balance is $4,340.63.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>
                    </div>

                </div>
            </div>
            </div>
        )
    }
}
