import React from 'react'
import Homeperformance from './homeperformance'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga'
import Benefit from './components/benefits'

ReactGA.initialize('UA-208545193-1')
export default class Home extends React.Component {
    state = {
        loader: true
    }

   componentDidMount() {
    ReactGA.pageview(window.location.pathname)
        window.addEventListener('DOMContentLoaded',async (event) => {
            localStorage.setItem('loader', 1)
            if(localStorage.getItem('loader') == 1){                
                setTimeout(() => {
                    
                    this.setState({ loader: false })
                    localStorage.setItem('loader', 2)                    
                }, 2000);             
            }
        });
        
        
        if (localStorage.getItem('loader') == 2) {
            this.setState({ loader: false })
        }
        var promise = new Promise(async (resolve, reject) => {
            if (localStorage.getItem('loader') == 1) {            
                    resolve(1)
            }else{
                resolve(1)
            }       
        })
       
        Aos.init({
            duration: 2000
        })
    }
    render() {
        return (

            <>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="keywords" content="Forex trading signals " />
                        <meta name="description" content="Fort Knox Global is a Forex trading company specializing in investment strategies and Forex trading signals dedicated to their customers." />
                        <title>Fort Knox - Profitable Forex Trading Signals</title>
                    </Helmet>
                    {this.state.loader &&
                        <div class="preloader">
                            <div class="preloader-inner">
                                <div class="preloader-icon">
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>}
                    <Navbar />
                    <section class="banner-section">
                        <img src="assets/banner.svg" alt="banner" class="img-fluid landing-banner-image" />
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12 row" >
                                    <div class="col-xl-8 banner-image-text">
                                        <h2>Strategic Asset Management </h2>
                                        <h6>Automate your portfolio strategy and optimize<br /> your potential returns. </h6>
                                        <div class="m-auto">
                                            <a href="" class="btn btn-primary" onClick={() => this.props.history.push('/getstarted')}>Start now</a>
                                        </div>
                                    </div>
                                    <div class="col-xl-4"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="about-fox">
                        <div class="container">
                            <div class="row align-items-center justify-content-center">
                                {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div>
            <img src="assets/Image 4.svg" alt="" class="img-fluid" />
        </div>
    </div> */}
                                {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"> */}
                                <div class="col-xl-8">
                                    <div class="right-section text-left">
                                        <h2 class="section-heading">About Fort Knox Global</h2>
                                        <h6 class="section-subheading">Innovative ideas for better returns</h6>
                                        <p>Fort Knox Global is a company that seeks to grow value for its shareholders by engaging in innovative financial solutions and strategies. </p>
                                        <p>We scout the globe to seek investment opportunities in both conventional and unconventional markets, using fundamentals and sound judgment with proven techniques that lead to good returns.</p>
                                        <a href="" class="btn btn-primary" onClick={() => this.props.history.push(`/about`)}>Explore more</a>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </section>
                    <section class="how-it-works">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="text-center">
                                        <h2 class="section-heading">How it works</h2>
                                        <h6 class="section-subheading">Take advantage of our trading experience and proven Forex trading strategy to obtain consistent profits.</h6>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                    <div class="text-center primary-card-shadow">
                                        <img src="assets/Icn-1.svg" alt="icon" class="img-fluid" />
                                        <h5 class="text-primary">Open a Forex Trading Account</h5>
                                        <p>Choose a Forex broker that offers copy-trading or PAMM accounts as desired.
                                        </p>
                                        <div class="primary-background mt-5"></div>
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                    <div class="text-center primary-card-shadow">
                                        <img src="assets/Icn-2.svg" alt="icon" class="img-fluid" />
                                        <h5 class="text-primary">Subscribe to Fort Knox Global’s account </h5>
                                        <p>Connect your forex trading account to Fort Knox Global copy-trading signals or PAMM account offerings.
                                        </p>
                                        <div class="primary-background mt-5"></div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                    <div class="text-center primary-card-shadow">
                                        <img src="assets/Icn-3.svg" alt="icon" class="img-fluid" />
                                        <h5 class="text-primary">Watch your portfolio grow
                                        </h5>
                                        <p>Monitor your portfolio. As Fort Knox Global’s performance grows, so does your portfolio.
                                        </p>
                                        <div class="primary-background mt-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Homeperformance />
                    <section class="benefits-section">
                      <Benefit />

                    </section>
                    <section class="services">
                        <div class="container">

                            <div class="row">
                                <div class="col-xl-12 ">
                                    <div class="text-center">
                                        <h2 class="section-heading ">Our Products</h2>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-xl-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                    <div class="flex primary-card-shadow our-product-box mb-3">
                                        <img src="assets/Icn-10.svg" alt="icon" class="img-fluid" />
                                        <div>
                                            <h5 class="text-primary">Silver Account (PAMM)</h5>
                                            <p class="mb-0">This is a profit-sharing subscription option on a predetermined basis. Via a Percentage Allocation Management Module Account, clients are able to allocate a percentage of their funds to be traded by Fort Knox Global. In this model, the client keeps 50% of profits and Fort Knox Global retains the remaining 50%.  </p>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-xl-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                    <div class="flex primary-card-shadow our-product-box">
                                        <img src="assets/Icn-11.svg" alt="icon" class="img-fluid" />
                                        <div>
                                            <h5 class="text-primary">Gold Account (Copy Trading)</h5>
                                            <p class="mb-0">This is a profit-sharing subscription option on a predetermined basis. Via a Percentage Allocation Management Module Account, clients are able to automatically copy the trades made in Fort Knox portfolio into their trading accounts with their Forex broker. </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                    <section class="question-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-6 left-section flex">
                                    <div class="text-center">
                                        <h2 class="section-heading text-left">Have questions?</h2>
                                    </div>
                                </div>
                                <div class="col-xl-6 right-section">
                                    <div class="flex" data-toggle="collapse" href="#betterment" role="button" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <p class="collapse-heading">What is copy trading?</p>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>

                                    <div class="collapse" id="betterment" class="collapse-data collapse">
                                        <div class="card card-body">
                                            <p>
                                                Copy trading is the action of duplicating the trades of someone else in your trading account. Usually, copy trading is used to copy the trades made by expert traders with a proven track record and profitability.
                                                The main purpose is to open in your account the same positions on the market as the expert you are copying.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex" data-toggle="collapse" href="#access" role="button" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <p class="collapse-heading">What is a PAMM Account?
                                        </p>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>

                                    <div class="collapse" id="access" class="collapse-data collapse">
                                        <div class="card card-body">
                                            <p>
                                                PAMM is the abbreviation of Percentage Allocation Management Module or Percentage Allocation Money Management. It is a form of trading in Forex markets with money collected from investors. PAMM accounts are dedicated to persons interested in obtaining profits from Forex trading, but who do not trade themselves. They choose an expert trader or money manager to trade in their name with their capital. A PAMM account has three actors: the Forex broker, the expert trader, and the investor.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex" data-toggle="collapse" href="#money" role="button" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <p class="collapse-heading">What Forex brokers do you support?</p>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>

                                    <div class="collapse" id="money" class="collapse-data collapse">
                                        <div class="card card-body">
                                            <p>
                                                You can choose any Forex broker that supports copy trading and/or PAMM accounts, depending on your product of choice. We recommend Tier 1 Forex brokers, which are well regulated in US, UK, Australian or European markets.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex " data-toggle="collapse" href="#experts" role="button" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <p class="collapse-heading">How does your service work?</p>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>

                                    <div class="collapse" id="experts" class="collapse-data collapse">
                                        <div class="card card-body">
                                            <b>We offer two products:</b><br />
                                            <p>
                                                <b>copy-trading,</b> which allows you to automatically copy in your trading account the positions our trading team places on the market. You will make similar profits on your account as we do on our accounts (with small variations based on your broker’s fees and commissions).
                                            </p>
                                            <p>

                                                <b>PAMM account,</b> where you participate with your capital and allocate a percentage of your funds to be traded by Fort Knox Globall Profits are shared on a predetermined basis.

                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </>
        )
    }
}
