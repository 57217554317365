import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

export default function Margincalculator() {
    const [levarage, setlevarage] = useState('1:1')
    const [lotsize, setlotsize] = useState(1)
    const [result, setresult] = useState(139240.00)
    const [gbpusdprice, setgbpusdprice] = useState(1.3924)

    const submitdata = (e) => {
        e.preventDefault()
        var a = (levarage.split(':'))
        setresult((gbpusdprice * lotsize * 100000) / parseFloat(a[0]))
    }
    return (
        <div class="container">
             <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Leverage Calculator" />
                    <meta name="description" content="Leverage Calculator calculates in a few clicks the margin your need to open a position in the Forex market while considering the level of risk accepted" />
                    <title>Leverage Calculator - Calculate optimum leverage for trading</title>
                </Helmet>
            <div class="row d-flex justify-content-center">
                <div class="col-xl-10">
                    <div class="calculator-box row d-flex justify-content-between">
                        <div class="calculator-form col-xl-6">
                            <form onSubmit={(e) => submitdata(e)}>
                                <div class="form-group" >
                                    <label for="levarage">Levarage</label>
                                    <select class="form-control" id="levarage" onChange={(e) => setlevarage(e.target.value)}>
                                        <option>1:1</option>
                                        <option>2:1</option>
                                        <option>3:1</option>
                                        <option>4:1</option>
                                        <option>5:1</option>
                                        <option>10:1</option>
                                        <option>20:1</option>
                                        <option>25:1</option>
                                        <option>30:1</option>
                                        <option>40:1</option>
                                        <option>50:1</option>
                                        <option>100:1</option>
                                        <option>200:1</option>
                                        <option>300:1</option>
                                        <option>400:1</option>
                                        <option>500:1</option>
                                        <option>600:1</option>
                                    </select>
                                </div>
                                <div class="form-group" >
                                    <label for="lotsize">Lots Size</label>
                                    <input type="number" class="form-control" id="lotsize"
                                        value={lotsize} onChange={(e) => setlotsize(e.target.value)} />
                                </div>
                                <div class="form-group"  >
                                    <label for="gbpusdprice">GBPUSD Price</label>
                                    <input type="number" class="form-control" id="gbpusdprice"
                                        value={gbpusdprice} onChange={(e) => setgbpusdprice(e.target.value)} />
                                </div>
                                <button class="btn btn-primary" type="submit">Calculate</button>
                            </form>
                        </div>
                        <div class="blue-box col-xl-6 d-flex pl-5 align-items-center">
                            <div >
                                <div>
                                    <p class="mb-0">Deposit amount</p>
                                    <h5 class="mb-0"> {parseFloat((result).toFixed(2)).toLocaleString('en')}  </h5>
                                    <p class="mb-0">Amount required to open a trade</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="defination">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="defination-box">
                                <h2>Definition</h2>
                                <p>Leverage is a mechanism that allows traders to open larger positions with less capital (margin). It multiplies the profit, but also the loss. When you trade using leverage you trade in margin. The leverage is offered by your Forex broker and in general, varies between 1:1 and 600:1. But not all brokers offer the maximum margin.
                                    Margin is the amount of money that is needed to open a trade on the Forex market. You can only open the trades you want if you have the corresponding margin available in your account. The margin is a part of your capital blocked in your account while you have orders opened on the market. The margin is released when the orders are closed. </p>
                                <h4>To retain</h4>
                                <ul>
                                    <li>Higher leverage equals higher risks, which might mean higher profit, but also, higher loss, if the market turns against your position.
                                        Professionals use a low leverage ratio and risk a very small part of their capital on an open position.</li>
                                </ul>
                                <h4>How does leverage work in Forex</h4>

                                <p>
                                    Opening an order with a 100:1 leverage means you can open a trade that is 100 times larger than if you have chosen not to use the leverage option.
                                    For example, if you want to buy 1 lot of GBPUSD at a quote of 1.300, without  leverage you need a margin of $130.000, with a leverage of 100:1 you need a margin of $1.300.
                                    The Forex Margin Calculator calculates based on your input the margin needed to place an order on the market.
                                </p>
                            </div>


                            <div class="defination-box">
                                <h2>Terms of the Compounding Calculator </h2>
                                <h4>Inputs</h4>
                                <ul >
                                    <li >
                                        <b> Currency pair: </b>   Choose the pair you want to trade. For example GBPUSD.
                                    </li>
                                    <li >
                                        <b> Deposit currency: </b>  Indicate your trading account currency. The margin values are different depending on the Forex pair and current quote on the market. For example USD.
                                    </li>
                                    <li >
                                        <b> Leverage: </b> Choose the leverage you want to use for your trade. For example 50:1.
                                    </li>
                                    <li >
                                        <b>Lots (trade size): </b>  Indicate the dimension of your trade. For example 0.2 lots..
                                    </li>
                                  
                                </ul>
                                <h4>Result</h4>

                                <ul >
                                    <li >
                                        <b>The calculator tells you the capital needed to open the trade.</b>
                                    </li>
                                  
                                </ul>
                                <h4>Example</h4>
                                <p> Let’s say you want to buy GBPUSD 0.2 lots, with a leverage of 50:1, at a quote of 1.3600. You need a margin of $544 in your account to be able to place this order.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </div>
    )
}
