import React, { Component } from 'react'
import baseURL from './baseurl'

export default class PerformanceTable extends Component {
  state = {
    positiondata: [],
    historydata: [],
    pageNumber: 0,
    page: 1,
    display: true,
    from: '',
    to: '',
    frominput: '',
    toinput: '',
    defaultdate: true,
    buttoncss: true,
    showdatainhistory: 'All',
    startdate: '',
    showrange: false
  }

  nextpage = async () => {
    if (this.state.page < this.state.pageNumber) {
      var sum = this.state.page + 1
      await this.setState({ page: sum })
    }
    this.componentDidMount()
  }
  prevpage = async () => {

    if (this.state.page > 1) {
      var sum = this.state.page - 1
      await this.setState({ page: sum })
    }
    this.componentDidMount()
  }
  searchdata = async (e) => {
    e.preventDefault()
    if (this.state.frominput.length < 10 || this.state.toinput.length < 10) {
      this.componentDidMount()
    }
    else {
      var fromarr = (this.state.frominput).split('-')
      var fromdate = `${fromarr[1]}/${fromarr[2]}/${fromarr[0]}`
      var toarr = (this.state.toinput).split('-')
      var todate = `${toarr[1]}/${toarr[2]}/${toarr[0]}`
      await this.setState({
        defaultdate: false,
        from: fromdate,
        to: todate
      })
      this.componentDidMount()
    }
  }
  historyfilter = async (e) => {
    if (e.target.value == 'All') {
      var alldate = new Date();
      var ad = ("0" + (alldate.getDate())).slice(-2)
      var am = ("0" + (alldate.getMonth() + 1)).slice(-2)
      var ay = alldate.getFullYear()
      var adate = `${am}/${ad}/${ay}`
      await this.setState({
        defaultdate: false,
        showrange: false,
        from: this.state.startdate,
        to: adate
      })
      this.componentDidMount()
    }
    else if (e.target.value == 'Yesterday') {
      var yesterdaydate = new Date();
      yesterdaydate.setDate(yesterdaydate.getDate() - 1)
      var yd = ("0" + (yesterdaydate.getDate())).slice(-2)
      var ym = ("0" + (yesterdaydate.getMonth() + 1)).slice(-2)
      var yy = yesterdaydate.getFullYear()
      var ydate = `${ym}/${yd}/${yy}`
      await this.setState({
        page: 1,
        defaultdate: false,
        showrange: false,
        from: ydate,
        to: ydate
      })
      this.componentDidMount()
    }
    else if (e.target.value == 'Last week') {
      var weeklydate = new Date();
      var sundayd = ("0" + (weeklydate.getDate())).slice(-2)
      var sundaym = ("0" + (weeklydate.getMonth() + 1)).slice(-2)
      var sundayy = weeklydate.getFullYear()
      var sunday = `${sundaym}/${sundayd}/${sundayy}`
      
      weeklydate.setDate(weeklydate.getDate() - 6)
      var mondayd = ("0" + (weeklydate.getDate())).slice(-2)
      var mondaym = ("0" + (weeklydate.getMonth() + 1)).slice(-2)
      var mondayy = weeklydate.getFullYear()
      var monday = `${mondaym}/${mondayd}/${mondayy}`
     
      await this.setState({
        page: 1,
        defaultdate: false,
        showrange: false,
        from: monday,
        to: sunday
      })
      this.componentDidMount()
    }
    else if (e.target.value == "Last month") {
      var monthdate = new Date()
      var lastd = ("0" + (monthdate.getDate())).slice(-2)
      var lastm = ("0" + (monthdate.getMonth() + 1)).slice(-2)
      var lasty = monthdate.getFullYear()
      var lastdate = `${lastm}/${lastd}/${lasty}`
     
      monthdate.setMonth(monthdate.getMonth() - 1)
      var firstd = ("0" + (monthdate.getDate())).slice(-2)
      var firstm = ("0" + (monthdate.getMonth() + 1)).slice(-2)
      var firsty = monthdate.getFullYear()
      var firstdate = `${firstm}/${firstd}/${firsty}`
     

      await this.setState({
        page: 1,
        defaultdate: false,
        showrange: false,
        from: firstdate,
        to: lastdate
      })
      this.componentDidMount()
    }
    else if (e.target.value == 'Date range') {
      await this.setState({
        page: 1,
        showrange: true
      })
    }
  }
  async componentDidMount() {
  
   
    await baseURL.get('/get-data/positionstable-data').then(async res => {
    
      await this.setState({ positiondata: res.data.result, startdate: res.data.startdate })

    
      var date = new Date();
      var ty = date.getFullYear()
      var tm = ("0" + (date.getMonth() + 1)).slice(-2)
      var td = ("0" + date.getDate()).slice(-2)
      var todaydate = `${tm}/${td}/${ty}`
    
      if (this.state.defaultdate) {
        await this.setState({ from: this.state.startdate, to: todaydate })
      }
      await baseURL.get(`/get-data/historytable-data?from=${this.state.from}&to=${this.state.to}&page=${this.state.page}&limit=10`).then(res => {
        if (res.data.count % 10) {
          var page = parseInt((res.data.count) / 10) + 1
        } else {
          var page = (res.data.count) / 10
        }
        this.setState({ historydata: res.data.result, pageNumber: page, startdate: res.data.startdate })
       
      })
    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return (
      <div className="performance-table">
        <div class="row ">
          <div class="col-xl-12 ">
            <div><a class={this.state.buttoncss ? "btn-line mr-3" : "btn-noline mr-3"} onClick={() => this.setState({ display: true, buttoncss: true })}>Open positions</a>
              <a class={this.state.buttoncss ? "btn-noline" : "btn-line"} onClick={() => this.setState({ display: false, buttoncss: false })}>History</a>
            </div>
            {this.state.display ?
              <div class="table-responsive mt-4">
                <table class="table table-inner">
                  <thead>
                    <tr>
                      <th scope="col">opentime</th>
                      <th scope="col">symbol</th>
                      <th scope="col">action</th>
                      <th scope="col">lots</th>
                      <th scope="col">openprice</th>
                      <th scope="col">tp</th>
                      <th scope="col">sl</th>
                      <th scope="col">profit</th>
                      <th scope="col">pips</th>
                      <th scope="col">swap</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.positiondata.map((data, i) => {
                      return (
                        <tr>
                          <td>{data.opentime} </td>
                          <td>{data.symbol} </td>
                          <td>{data.action} </td>
                          <td>{data.sizing_value} </td>
                          <td>{data.openprice} </td>
                          <td>{data.tp} </td>
                          <td>{data.sl} </td>
                          <td>{data.profit} </td>
                          <td>{data.pips} </td>
                          <td>{data.swap} </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              :

              <div class="table-responsive pt-2 mt-2">
                <div class="row d-flex my-3 border-top pt-3">
                  <div class="col-xl-4">
                    <select class="form-control form-control" onChange={(e) => this.historyfilter(e)}>
                      <option>All</option>
                      <option>Yesterday</option>
                      <option>Last week</option>
                      <option>Last month</option>
                      <option>Date range</option>
                    </select>
                  </div>
                  <div>
                    {this.state.showrange &&
                      <div class="">
                        <form class="form-inline d-flex justify-content-end">
                          <div class="form-group mx-sm-3 mb-2">
                            <label class="col-sm-2 col-form-label mr-2">From  </label>
                            <input class="form-control" type="date" onChange={(e) => this.setState({ frominput: e.target.value })}
                              placeholder="MM/DD/YYYY" />
                          </div>
                          <div class="form-group mx-sm-3 mb-2">
                            <label class="col-sm-2 col-form-label">To </label>
                            <input class="form-control" type="date" onChange={(e) => this.setState({ toinput: e.target.value })}
                              placeholder="MM/DD/YYYY" />
                          </div>
                          <button onClick={(e) => this.searchdata(e)} class="btn btn-primary mb-2">search</button>
                        </form>
                      </div>

                    }
                  </div>
                </div>
                <div>
                  <table class="table table-inner">
                    <thead>
                      <tr>
                        <th scope="col">OpenTime</th>
                        <th scope="col">CloseTime</th>
                        <th scope="col">symbol</th>
                        <th scope="col">action</th>
                        <th scope="col">lots</th>
                        <th scope="col">openprice</th>
                        <th scope="col">closeprice</th>
                        <th scope="col">tp</th>
                        <th scope="col">sl</th>
                        <th scope="col">pips</th>
                        <th scope="col">profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.historydata.map((data, i) => {
                        return (
                          <tr>
                            <td>{data.openTime}</td>
                            <td>{data.closeTime}</td>
                            <td>{data.symbol}</td>
                            <td>{data.action}</td>
                            <td>{data.sizing_value}</td>
                            <td>{data.openPrice}</td>
                            <td>{data.closePrice}</td>
                            <td>{data.tp}</td>
                            <td>{data.sl}</td>
                            <td>{data.pips}</td>
                            <td>{data.profit}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div class="row d-flex justify-content-between mb-3">
                  <div class="">
                    {this.state.page !== 1 && <button class="btn btn-primary ml-4" onClick={this.prevpage}>Previous</button>}
                  </div>
                  <div class="">
                    {(this.state.page === this.state.pageNumber || this.state.pageNumber === 0) ? "" : <button class="btn btn-primary float-right mr-5" onClick={this.nextpage}>Next</button>}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
