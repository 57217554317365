import React, { useState } from 'react'

export default function Averagepositioncalculator() {
    const [openposition, setopenposition] = useState('3')
    const [lotsize, setlotsize] = useState(1)
    const [result, setresult] = useState("-3997")
    const [validationopenposition, setvalidationopenposition] = useState(false)
    const [validationlotsize, setvalidationlotsize] = useState(false)


    const submitdata =async (e) => {
        e.preventDefault()
        if(openposition>10 || openposition<1 || isNaN(openposition  )){setvalidationopenposition(true)}
        else if(lotsize>100 || lotsize<0 || isNaN(lotsize)){setvalidationlotsize(true)
            setvalidationopenposition(false)}
        
       else{
        var data = (openposition - 4000) * lotsize
        await setresult(data)
       }
       
    }

    return (

        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-xl-10">
                    <div class="calculator-box d-flex justify-content-between">
                        <div class="calculator-form col-xl-6">
                            <form onSubmit={(e) => submitdata(e)}>
                                <div class="form-group">
                                    <label for="openposition">Current open position</label>
                                    <input type="number" class="form-control" id="openposition"
                                        value={openposition} onChange={(e) => setopenposition(parseFloat(e.target.value))} />
                                    {validationopenposition && <div class="validation-bar">*enter current open position(1-10)</div>}
                                </div>
                                <div class="form-group">
                                    <label for="lotsize">Current lot size</label>
                                    <input type="number" class="form-control" id="lotsize"
                                        value={lotsize} onChange={(e) => setlotsize(parseFloat(e.target.value))} />
                                      {validationlotsize && <div class="validation-bar">*enter lot size</div>}
                                </div>
                                <button type="submit" class="btn btn-primary">Calculate</button>
                            </form>
                        </div>
                        <div class="col-xl-6 blue-box d-flex pl-5 align-items-center">
                            <div>
                            <p class="mb-0">Average Position</p><h5 class="mb-0">{(parseFloat(result)).toLocaleString('en')} </h5>
                            
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
