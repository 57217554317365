import React, { Component } from 'react'
import baseURL from './baseurl'

export default class Subscriptioncapital extends Component {
    state = {
        subscription: 'Gold - $125 per month',
        goal: "Break Even (monthly subscription equals monthly profit)",
        capitalminimum: 0,
        subscriptionvalue: 125,
        goalvalue: 1,
        monthlyroi: 0,
        defaultvalue:true
    }
    submitdata =async (e) => {
        e.preventDefault()
        var goalarr = [
            'Break Even(monthly subscription equals monthly profit)',
            '1X Subscription (monthly profit is 100% of monthly subscription fee)',
            '2X Subscription (monthly profit is 200% of monthly subscription fee)',
            '5X Subscription (monthly profit is 500% of monthly subscription fee)',
            '10X Subscription (monthly profit is 1000% of monthly subscription fee)'
        ]
        for(let i=0;i<5;i++){
            if(this.state.goal == goalarr[i]){
              await  this.setState({goalvalue:(i+1),defaultvalue:false})
            }
        }
        this.componentDidMount()
    }
    async componentDidMount() {
     if(this.state.defaultvalue){   await baseURL.get('/get-data/calculator-data').then(async res => {
            await this.setState({ monthlyroi: parseFloat(res.data.Monthlyroi) })
        })
    }
        var capminimum = (this.state.subscriptionvalue * this.state.goalvalue) / this.state.monthlyroi
        await this.setState({ capitalminimum: capminimum })
    }
    render() {
        return (
            <div class="calculator">
            <div class="container">
                <div class="row d-flex">
                    <div class="col-xl-7">
                    <div class="calculator-form">
                        <h4>Please enter the following information</h4>
                        <form onSubmit={(e) => this.submitdata(e)}>
                            <div class="form-group">
                                <label for="subscription">Subscription</label>
                                <select class="form-control" id="subscription"
                                    onChange={(e) => this.setState({ subscription: e.target.value })}>
                                    <option>Gold - $125 per month</option>
                                </select>
                            </div>
                            <div>
                                <label for="goal">Goal</label>
                                <select class="form-control" id="goal"
                                    onChange={(e) => this.setState({ goal: e.target.value })}>
                                    <option>Break Even (monthly subscription equals monthly profit)</option>
                                    <option>1X Subscription (monthly profit is 100% of monthly subscription fee)</option>
                                    <option>2X Subscription (monthly profit is 200% of monthly subscription fee)</option>
                                    <option>5X Subscription (monthly profit is 500% of monthly subscription fee)</option>
                                    <option>10X Subscription (monthly profit is 1000% of monthly subscription fee)</option>
                                </select>
                            </div>
                            <div class="mt-5 d-flex">
                                <p style={{ fontSize: "20px" }}>Monthly ROI: </p>
                                <h5 style={{marginLeft:"10px",color:"#0082F2"}}>{this.state.monthlyroi}%</h5>
                            </div>
                            <button class="btn btn-primary" type="submit">Calculate</button>
                        </form>
                        </div>
                    </div>
                    <div class="col-xl-5 blue-box d-flex pl-5 align-items-center">
                        <div class="">
                            {/* <p class="mb-0">monthly Payment</p><h5 class="mb-0">${(this.state.monthlypayments).toFixed(2)}</h5> */}
                            <p class="mb-0">Capital minimum</p><h5 class="mb-0">${parseFloat((this.state.capitalminimum).toFixed(2)).toLocaleString('en')}</h5>
                            <p class="mb-0" style={{ fontSize: "14px", maxWidth: "350px" }}>Capital required in your trading account to achieve stated goals.</p>
                            <div class="border-center"></div>
                            <p class="mb-0" style={{ fontSize: "14px", maxWidth: "350px" }}>Capital calculation is based on
                                real-time monthly rate of return. It is not a guarantee of future returns.</p>
                        </div>
                    </div>

                </div>
            </div>
            </div>
        )
    }
}
