import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class Loancalculator extends Component {
    state = {
        loanamount: 10000,
        loanterminmonth: 12,
        loaninterest: 5,
        monthlypayments: 856.07,
        totalinterest: 272.90,
        loanamountinput: 10000,
        validationinterest: false
    }
    submitdata = async (e) => {

        e.preventDefault()
        if (this.state.loaninterest > 100 || this.state.loaninterest < 0) {
            this.setState({ validationinterest: true })
        }
        else {
            await this.setState({
                loanamount: this.state.loanamountinput,
                validationinterest: false
            })
            this.componentDidMount()
        }

    }
    componentDidMount() {
        var principal = parseFloat(this.state.loanamount);
        var interest = parseFloat(this.state.loaninterest) / 100 / 12;
        var payments = parseFloat(this.state.loanterminmonth);

        // Now compute the monthly payment figure, using esoteric math.
        var x = Math.pow(1 + interest, payments);
        var monthly = (principal * x * interest) / (x - 1);
        var totalint = monthly * parseFloat(this.state.loanterminmonth) - parseFloat(this.state.loanamount)
        this.setState({ monthlypayments: monthly, totalinterest: totalint })
    }
    render() {
        return (
            <div class="calculator">
            <div class="container">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Forex Loan Calculator" />
                    <meta name="description" content="Forex Loan Calculator helps in deciding if a Forex investment is still profitable if you have to borrow the money from your bank or a third party" />
                    <title>Forex Loan Calculator - How much cost to borrow the money</title>
                </Helmet>
                <div class="row d-flex">
                    <div class="col-xl-6">
                        <div class="calculator-form">
                        <h4>Please enter the following information</h4>
                        <form onSubmit={(e) => this.submitdata(e)}>
                            <div class="form-group">
                                <label for="loanamount">Loan amount</label>
                                <input type="number" class="form-control" id="loanamount"
                                    value={this.state.loanamountinput} onChange={(e) => this.setState({ loanamountinput: e.target.value })} />
                            </div>
                            <div class="form-group">
                                <label for="loanterm">Loan term in months</label>
                                <input type="number" class="form-control" id="loanterm"
                                    value={this.state.loanterminmonth} onChange={(e) => this.setState({ loanterminmonth: e.target.value })} />
                            </div>
                            <div class="form-group">
                                <label for="loaninterest">Interest rate per year</label>
                                <input type="number" class="form-control" id="loaninterest"
                                    value={this.state.loaninterest} onChange={(e) => this.setState({ loaninterest: parseFloat(e.target.value) })} />
                                {this.state.validationinterest && <div class="validation-bar">*should be 0-100</div>}
                            </div>
                            <button class="btn btn-primary" type="submit">Calculate</button>
                        </form>
                        </div>
                    </div>
                    <div class="col-xl-6 blue-box d-flex pl-5 align-items-center">
                        <div class="">
                            {/* <p class="mb-0">monthly Payment</p><h5 class="mb-0">${(this.state.monthlypayments).toFixed(2)}</h5> */}
                            <p class="mb-0">Interest Accrued </p><h5 class="mb-0">{parseFloat((this.state.totalinterest).toFixed(2)).toLocaleString('en')}</h5>
                            {/* <p class="mb-0">USD</p> */}
                            <div class="border-center"></div>
                            <p class="mb-0">Loan Balance</p> <h5 class="mb-0">{parseFloat((parseFloat(this.state.loanamount) + parseFloat(this.state.totalinterest)).toFixed(2)).toLocaleString('en')}</h5>
                            {/* <p class="mb-0">USD</p> */}
                        </div>
                    </div>

                </div>
                <section class="defination">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="defination-box">
                                    <h2>Definition</h2>
                                    <p>  A Loan Calculator is a tool that helps calculate your costs and monthly payments if you want to borrow a sum of money from a credit line. And compare these expenses with the loan to potential investments that can generate higher profits, if the same amount of money is used this way.</p>

                                </div>


                                <div class="defination-box">
                                    <h2>  Terms of the Loan Calculator</h2>
                                    <h4>Inputs</h4>
                                    <ul >
                                        <li >
                                            <b> Loan Amount: </b> Insert the sum you need to borrow. For example $5,000.
                                        </li>
                                        <li >
                                            <b>  Interest Rate: </b> Indicate the interest rate you pay for your loan. For example 6% per year.
                                        </li>
                                        <li >
                                            <b> Loan Term: </b>   Choose the interval of time you want to make the loan for. For example 2 years.
                                        </li>
                                    </ul>
                                    <h4>Result</h4>

                                    <ul >
                                        <li >
                                            <b>  Monthly Payment: The calculator will indicate the sum of money you have to pay monthly for your loan.</b>
                                        </li>
                                        <li >
                                            <b>  Total Payment: The calculator will display the total amount you have to pay back for the loan.</b>
                                        </li>
                                        <li >
                                            <b>   Total Interest: The calculator will show the interest you have to pay for the borrowed money.</b>
                                        </li>
                                        <li >
                                            <b>  Annual Payment: The sum you have to pay yearly for the loan.</b>
                                        </li>
                                    </ul>
                                    <h4>Example</h4>
                                    <p> Let’s say you came across an opportunity of investment that has the potential to generate high returns. You need to invest $5,000, but you don’t have this amount available at the moment. However, you can borrow it from a line of credit. And you need to know your costs with this loan. You want to return the loan in 2 years and have an interest rate of 6% per year.

                                        Using the Loan Calculator you find out that you will have a monthly payment of $221.6, a total interest of $318.4, a total payment of $5,318.4, and an annual payment of $2,659.2. Now, you have all the cost details to compare with the potential returns of your opportunity.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                </div>
            </div>
        )
    }
}
