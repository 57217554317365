import React, { useEffect } from 'react'
import Aos from 'aos'
import ReactGA from 'react-ga'

function Benefit(){
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname)
        Aos.init({
            duration: 2000
        })
    })
    return(
        <div>
             <div class="container">
                        <div class="row ">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h2 class="section-heading">Benefits</h2>
                                    <h6 class="section-subheading">We make forex trading work for you</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row section justify-content-center">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                <div class="benefit-box">
                                    <img src="assets/Icn-4.svg" alt="icon" class="img-fluid" />
                                    <h5>Transparency </h5>
                                    <p>No surprises, you can study our trades in real time to get a clear idea of how our trading strategy performs </p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                <div class="benefit-box">
                                    <img src="assets/Icn-5.svg" alt="icon" class="img-fluid" />
                                    <h5>Risk Manangment</h5>
                                    <p>Conservative risk approach: We maintain a strict minimum and maximum Capital risk</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                <div class="benefit-box">
                                    <img src="assets/Icn-6.svg" alt="icon" class="img-fluid" />
                                    <h5>No Commitment </h5>
                                    <p>Feel free to cancel your subscription at anytime</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                <div class="benefit-box">
                                    <img src="assets/Icn-9.svg" alt="icon" class="img-fluid" />
                                    <h5>Attractive Returns</h5>
                                    <p>Based on previous data we aim to replicate a minimum of 42% annual return </p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                                <div class="benefit-box">
                                    <img src="assets/Icn-8.svg" alt="" class="img-fluid" />
                                    <h5>Autonomy</h5>
                                    <p>Your investment remains in your account, at the currency broker, always, thereby giving you full access.</p>
                                </div>
                            </div>
                            {/* <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear" >
                                <div class="benefit-box">
                                    <img src="assets/Icn-7.svg" alt="icon" class="img-fluid" />
                                    <h5>Trade mainly on profits</h5>
                                    <p> Starting with the second month of positive growth, the risk associated with the current open trades run on the first month profits.Thus, you have a reduced risk on your capital.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
        </div>
    )
}

export default Benefit;