import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'
import { Link } from 'react-router-dom'
import {Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-208545193-1')

export default class Learn extends React.Component {
  state = {
    renders: true
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname)
    Aos.init({
      duration: 2000
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="keywords" content="Learn Forex" />
          <meta name="description" content="Whether you trade on your own or hire someone else to trade for you, you should learn Forex basic concepts to oversee your portfolio evolution" />
          <title>Learn Forex Basic Concepts in a Glance</title>
        </Helmet>
        <Navbar />
        <section class="Performance-subheading">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="text-center">
                  <h4>Learn</h4>
                </div>
              </div>
            </div>

          </div>
        </section>



        <section id="steps" class="steps">
          <div class="container">

            <div class="row no-gutters aos-init aos-animate" data-aos="fade-up">




              <div class="col-xl-10" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                <div class="flex  px-5">

                  <div>

                    <p>As an investor interested in obtaining profits on the financial markets, no matter if you decide to trade yourself or use a third party to trade for you, you should be familiar with the basic concepts of trading. For the simple reason that you need to understand how trading works, in general, and what’s the situation of your portfolio, in particular. </p>
                    <p>And, also, you should know the tools that can ease your task of assessing and forecasting the evolution of your investments. </p>
                    <p> <b>Here is an overview of the main trading terms you should be familiar with:</b></p>
                  </div>

                </div>
              </div>





              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">

                <h4>Profitability Rates</h4>
                <p>The most important rates that shows the profitability of your investments.</p>
              </div>

              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">

                <h4>Risk </h4>
                <p>Trading in financial markets implies capital risks. You should assume only calculated risks.</p>
              </div>

              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">

                <h4> Risk Management</h4>
                <p>Apply risk management rules to control risk and conserve your capital </p>
              </div>

              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">

                <h4>Compounding</h4>
                <p>Constant profits multiply in the long run via compounding,  if you let them run.</p>
              </div>

              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">

                <h4>Market Volatility </h4>
                <p>Markets are impacted by various news, economic output and data. And they move in consequence, sometimes sharply.</p>
              </div>

              <div class="col-lg-4 col-md-6 content-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">

                <h4>Psychology of Trading</h4>
                <p>Our feelings as humans dictate the evolution of markets and that of our trades. Learn to depict the psychology of trading for improved trading results.</p>
              </div>

            </div>

          </div>
        </section>
        <Footer />
      </div>
    )
  }
}
