import React, { Component } from 'react'
import baseURL from './baseurl'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ReactGA from 'react-ga'

ReactGA.initialize('UA-208545193-1')

export default class Compoundcalculator extends Component {
    state = {
        investment: 20000,
        time: 10,
        rateofreturn: '',
        investmentinput: 20000,
        timeinput: 10,
        rateofreturninput: '',
        lastbalance: 0,
        totalgain: 0,
        arr: [],
        defaultvalue: true,
        defaultrate: '',
        validationtime: false,
        validationrate: false,
        validationinvestmentinput: false
    }

    providedata = async (e) => {
        e.preventDefault()
        if (isNaN(this.state.investmentinput)) { await this.setState({ validationinvestmentinput: true }) }
        else if (this.state.timeinput > 60 || this.state.timeinput < 1 || isNaN(this.state.timeinput)) { await this.setState({ validationtime: true, validationinvestmentinput: false }) }
        else if (this.state.rateofreturninput > 100 || this.state.rateofreturninput < 0 || isNaN(this.state.rateofreturninput)) { await this.setState({ validationrate: true, validationtime: false, validationinvestmentinput: false }) }
        else {
            await this.setState({
                validationtime: false,
                validationrate: false,
                defaultvalue: false,
                investment: parseInt(this.state.investmentinput),
                time: parseInt(this.state.timeinput),
                rateofreturn: parseFloat(this.state.rateofreturninput)
            })
            this.componentDidMount()
        }
    }
    calculatewithdefault = async (e) => {
        e.preventDefault()
        await this.setState({ defaultvalue: true })
        this.componentDidMount()
    }
    async componentDidMount() {
        ReactGA.pageview(window.location.pathname)
        // this.setState({investmentinput:this.state.investmentinput.toLocaleString('en')})
        if (this.state.defaultvalue) {
            await baseURL.get('/get-data/calculator-data').then(async res => {
                await this.setState({
                    rateofreturn: parseFloat(res.data.ROI),
                    rateofreturninput: parseFloat(res.data.ROI),
                    defaultrate: parseFloat(res.data.ROI)
                })
            })
        }

        this.state.arr = []
        var startingbalance = this.state.investment
        var rate = this.state.rateofreturn
        var amount = this.state.investment * ((rate + 100) / 100)
        for (var i = 0; i < this.state.time; i++) {
            this.state.arr.push({
                initial: startingbalance,
                rate: rate,
                amount: amount
            })
            this.setState({ totalgain: rate })
            rate = rate + this.state.rateofreturn + ((rate * this.state.rateofreturn) / 100)
            startingbalance = amount
            this.setState({ lastbalance: amount })
            amount = this.state.investment * (100 + rate) / 100
        }
    }
    HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9', color: 'black', maxWidth: 220, fontSize: 12, border: '1px solid black',
        },
    }));
    render() {
        return (
            <div>

                <section class="calculator">
                    <div class="container">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="text-center">

                                </div>
                            </div>
                        </div>

                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-10">
                                <div class="calculator-box d-flex justify-content-between">

                                    <div class="calculator-form">
                                        <h4 >Please enter the following information</h4>

                                        <form onSubmit={(e) => this.providedata(e)}>
                                            <div class="form-group">
                                                <label for="yourinvestment">Your Investment</label>
                                                <input type="number" value={this.state.investmentinput}  
                                                    onChange={(e) => this.setState({ investmentinput: parseFloat(e.target.value) })} class="form-control" id="yourinvestment"
                                                />
                                                {this.state.validationinvestmentinput && <div class="validation-bar">*please inter amount</div>}
                                            </div>
                                            <div class="form-group">
                                                <label for="investmenttime">Number of periods</label>
                                                <input type="number" class="form-control" id="investmenttime"
                                                    value={this.state.timeinput} onChange={(e) => this.setState({ timeinput: parseFloat(e.target.value) })} />
                                                {this.state.validationtime && <div class="validation-bar">*should be 1-60</div>}
                                            </div>
                                            <div class="form-group">
                                                <label for="rateofreturn">Gain % per period</label>
                                                <input type="number" class="form-control" id="rateofreturn" step="any"
                                                    value={this.state.rateofreturninput} onChange={(e) => this.setState({ rateofreturninput: parseFloat(e.target.value) })} />
                                                {this.state.validationrate && <div class="validation-bar">*should be 0-100</div>}
                                            </div>
                                            <button class="btn btn-primary" type="submit">Calculate</button>
                                            <p class="mb-4"><a class="btn-line" onClick={(e) => this.calculatewithdefault(e)}>This is fortknoxglobal last 30 days ROI   </a> <span>{this.state.defaultrate}%</span>
                                                <this.HtmlTooltip arrow placement="right"
                                                    title={<>This is Fort Knox Global's current real-time ROI. See performance page for more details. </>}>
                                                    <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                                                </this.HtmlTooltip>
                                            </p>

                                        </form>
                                    </div>

                                    <div class="blue-box pl-5  d-flex align-items-center">
                                        <div>
                                            <p class="mb-0"> Ending Balance </p><h5 class="mb-0">{parseFloat((this.state.lastbalance).toFixed(2)).toLocaleString('en')}</h5>

                                            <div class="border-center"></div>
                                            <p class="mb-0"> Total Gain</p><h5 class="mb-0"> {(this.state.totalgain).toFixed(2)}% </h5>
                                            <p class="mt-0">Percent</p>                                                </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="table-section">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-10 ">
                                <div class="text-center">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col">Starting Balance</th>
                                                <th scope="col">Total Gain</th>
                                                <th scope="col">Ending Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.arr.map((data, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{i+1}</td>
                                                            {Number.isInteger(data.initial) ? <td>{(data.initial).toLocaleString('en')}</td> : <td>{parseFloat((data.initial).toFixed(2)).toLocaleString('en')}</td>}
                                                            {Number.isInteger(data.rate) ? <td>{data.rate}</td> : <td>{(data.rate).toFixed(2)}</td>}
                                                            {Number.isInteger(data.amount) ? <td>{(data.amount).toLocaleString('en')}</td> : <td>{parseFloat((data.amount).toFixed(2)).toLocaleString('en')}</td>}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>

                </section>


            </div>
        )
    }
}
