import React from 'react';

function Subscription() {
    return (
        <div>
            <div class="container">

                <div class="row">
                    <div class="col-xl-12 py-4">
                        <div class="text-center">
                            <h2 class="section-heading ">Sign up for our PAMM account</h2>
                        </div>
                    </div>
                </div>

                <div class="row aos-init aos-animate d-flex justify-content-center" data-aos="fade-left">



                    <div class="col-lg-4 col-md-6 mt-4 mt-md-0  aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                        <div class="pricing-box box featured" >
                            <h3>Gold Account</h3>
                            <h4>50% : 50%</h4>
                            <ul>
                                <li>PAMM Profit Sharing </li>

                            </ul>
                            <div class="btn-wrap text-left" style={{ fontSize: "12px" }}>

                                This is a profit-sharing subscription option on a predetermined basis. Via a Percentage Allocation Management Module Account, clients are able to allocate a percentage of their funds to be traded by Fort Knox Global. In this model, the client keeps 50% of profits and Fort Knox Global retains the remaining 50%.

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6  aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                    <a href="https://www.mql5.com/en/signals/1396639?source=Site+Signals+MT5+Tile+All+Search%3aFORT+KNOX" target="_blank" style={{color:"black",textDecoration:"none"}}>
                        <div class="box pricing-box featured" >
                            <h3>Silver Account</h3>
                            <h4>50% : 50%</h4>
                            <ul>
                                <li>PAMM Profit Sharing </li>

                            </ul>
                            <div class="btn-wrap text-left" style={{ fontSize: "12px" }}>

                                This is a profit-sharing subscription option on a predetermined basis. Via a Percentage Allocation Management Module Account, clients are able to allocate a percentage of their funds to be traded by Fort Knox Global. In this model, the client keeps 50% of profits and Fort Knox Global retains the remaining 50%.

                            </div>
                        </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-md-6  aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                    <a href="https://www.mql5.com/en/signals/1257775?source=Site+Signals+MT4+Tile+All+Search%3aFORT+KNOX" target="_blank" style={{color:"black",textDecoration:"none"}}>
                        <div class="box pricing-box featured" >
                            <h3>Bronze Account</h3>
                            <h4>50% : 50%</h4>
                            <ul>
                                <li>PAMM Profit Sharing </li>

                            </ul>
                            <div class="btn-wrap text-left" style={{ fontSize: "12px" }}>

                                This is a profit-sharing subscription option on a predetermined basis. Via a Percentage Allocation Management Module Account, clients are able to allocate a percentage of their funds to be traded by Fort Knox Global. In this model, the client keeps 50% of profits and Fort Knox Global retains the remaining 50%.

                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription;