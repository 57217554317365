import React, { Component } from 'react'
import { Link ,NavLink} from 'react-router-dom'
import { Nav, Tab, Tabs } from 'react-bootstrap'

export default class Navbar extends Component {
    render() {
        return (
            <div>
                <header class="nav-down">
                    <nav class=" navbar navbar-expand-lg navbar-light bg-light">
                        <a class="navbar-brand" href="#">
                            <img src="/assets/logo.svg" alt="" class="img-fluid" alt="logo"/>
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse " id="navbarNavDropdown">
                            <ul class=" d-flex justify-content-center align-items-center navbar-nav ml-auto mr-4" >
                                
                                <li ><NavLink to='/' activeClassName="current" exact={true}> Home </NavLink> </li>
                                <li ><NavLink to='/about' activeClassName="current"> About Us</NavLink> </li>
                                <li ><NavLink to='/products' activeClassName="current"> Products </NavLink> </li>
                                <li ><NavLink to='/performance' activeClassName="current"> Performance </NavLink> </li>
                               
                                <li><NavLink to='/calculator' activeClassName="current"> Calculators </NavLink> </li>
                                <li><NavLink to='/learn' activeClassName="current" > Learn </NavLink> </li>
                                <li ><NavLink to='/getstarted' activeClassName="current" class="btn btn-primary "> Get Started </NavLink> </li>
                                {/* <li class="nav-item"> <a class="nav-link " href="#"><span class="btn-primary">Let's Chat</span></a> </li> */}
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
        )
    }
}
// onClick={()=>window.location.href = ('/learn')}