import React, { Component } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'

export default class FAQ extends Component {
    componentDidMount() {
        Aos.init({
            duration: 2000
        })
    }

    render() {
        return (

            <div>
                <Navbar />
                <section class="Performance-subheading">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h4> Frequently Asked Questions </h4>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>



                <section class="question-section">
                    <div class="container">
                        <div class="row d-flex justify-content-center">

                            <div class="col-xl-8 right-section">
                                <div class="flex" data-toggle="collapse" href="#betterment" role="button" aria-expanded="false"
                                    aria-controls="collapseExample">
                                    <p class="collapse-heading">What is copy trading?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="betterment" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>Copy trading is the action of duplicating the trades of someone else in your trading account.Usually, copy trading is used to copy the trades made by expert traders with a proven track record and profitability.The main purpose is to open in your account the same positions on the market as the expert you are copying.
                                        </p>
                                    </div>
                                </div>
                                <div class="flex" data-toggle="collapse" href="#access" role="button" aria-expanded="false"
                                    aria-controls="collapseExample">
                                    <p class="collapse-heading">What is a PAMM Account?
                                    </p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="access" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>PAMM is the abbreviation of Percentage Allocation Management Module or Percentage Allocation Money Management.It is a form of trading in Forex markets with money collected from investors.PAMM accounts are dedicated to persons interested in obtaining profits from Forex trading, but who do not trade themselves.They choose an expert trader or money manager to trade in their name with their capital.A PAMM account has three actors: the Forex broker, the expert trader, and the investor.
                                        </p>
                                    </div>
                                </div>
                                <div class="flex" data-toggle="collapse" href="#money" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">What Forex brokers do you support?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="money" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>You can choose any Forex broker that supports copy trading and/or PAMM accounts, depending on your product of choice. We recommend Tier 1 Forex brokers, which are well regulated in US, UK, Australian or European markets.
                                        </p>
                                    </div>
                                </div>

                                <div class="flex" data-toggle="collapse" href="#experts" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">How does your service work?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>
                                            <h6><b>We offer two products: </b></h6>

                                            <b>copy-trading,</b> which allows you to automatically copy in your trading account the positions our trading team places on the market. You will make similar profits on your account as we do on our accounts (with small variations based on your broker’s fees and commissions).<br />

                                            <b>PAMM account,</b> where you participate with your capital and allocate a percentage of your funds to be traded by Fort Knox Globall Profits are shared on a predetermined basis.
                                        </p>

                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts1" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">What is MQL5?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts1" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> MQL5 or MetaQuotes Language 5 is a programming language used for the trading platform MetaTrader.MetaTrader is the most used trading platform in the world today.MQL5 offers the possibility to build trading robots, technical indicators, libraries, scripts, etc.In a word, MQL5 allows you to create a personalized algorithm for your trading.
                                        </p>
                                    </div>
                                </div>

                                <div class="flex" data-toggle="collapse" href="#experts2" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">How much of my money is at risk?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts2" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>At any point in time a maximum of
                                            5.6% of your capital will be at risk, no matter the number of trades opened.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts3" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Do I need to install anything on my computer?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts3" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> For the copy trading option, yes, you need to install the trading platform MetaTrader and connect your mql5.com account to your platform to allow the copy trading operations.
                                            <br />For the PAMM account, no you don’t need to install anything.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts4" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Do I have access to my money?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts4" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> For the copy trading option - Yes, you always have access to your money, as they are in your trading account, opened with your Forex broker in your name.You can withdraw and deposit money anytime you want.
                                            <br />
                                            For the PAMM account option.- Yes, you can withdraw and deposit money from the PAMM account anytime.
                                        </p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts5" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">How can I verify that these trades and performance are real?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts5" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> you can follow our account on mql5.com, check the “Performance” section of this site, that is connected to our trading account. And also preview our live trading accounts on the MT4 platform under OANDA.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts6" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Do you make the trades for me?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts6" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> If you choose a copy trading option, no.Our trades will be automatically copied into your account.<br />
                                            If you choose a PAMM account, yes, we make the trades for you.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts7" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Do I have to execute the trades myself?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts7" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> No, you don’t.If you choose a copy trading option the trades will be copied and executed automatically on your account.If you choose a PAMM account we will execute the trades for you.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts8" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Why are there multiple trades open on my account?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts8" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p>According to our trading strategy, our expert traders might identify multiple trading opportunities in a short span of time and for this reason, there will be multiple trades opened simultaneously.</p>
                                    </div>
                                </div>


                                <div class="flex" data-toggle="collapse" href="#experts9" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Why haven’t you closed out this trade?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts9" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> Most often a trade is still open because it didn’t reach its take profit level and our traders consider the initial scenario is still valid.As such, they decide to keep the position open for a longer period.</p>
                                    </div>
                                </div>

                                <div class="flex" data-toggle="collapse" href="#experts10" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">Why are trades open for multiple days?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts10" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> Trades are open for multiple days because the forecasted level of profit was not reached yet and the market evolves in the expected direction.Consequently, the position will be kept open for several days, as long as the evolution of the market is favorable.</p>
                                    </div>
                                </div>

                                <div class="flex" data-toggle="collapse" href="#experts11" role="button" aria-expanded="false"
                                    aria-controls="collapseExample" >
                                    <p class="collapse-heading">What is averaging down?</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="collapse" id="experts11" class="collapse-data collapse">
                                    <div class="card card-body">
                                        <p> Averaging down is a common investment strategy that implies adding to a position, buying supplementary lots of a currency pair after the price has decreased.Actually, it means executing a second purchase at a lower price.This way the average price for the respective currency pair diminishes and the number of pips needed for the whole order to reach the profit level is lower.</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section >
                <Footer />
            </div >
        )
    }

}