import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Aos from 'aos'

export default function Openmql5account() {
    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    })
    return (
        <div>
            <Navbar />
            <section class="Performance-subheading">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="text-center">
                                <h4>Get Started</h4>
                            </div>
                        </div>
                    </div>

                </div>

            </section>


            <section id="steps" class="steps">
                <div class="container">

                    <div class="row no-gutters aos-init aos-animate" data-aos="fade-up">
                        <div class="col-xl-12" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                            <div class=" px-5">

                                <div>
                                    <h5 class="  my-4"> Open an MQL5 Account and Choose Fort Knox as Forex Signal Service</h5>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 "> <span >1</span>Create an account</h5>

                                    <img src="/assets/12.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image" />
                                    <p class="mx-5">Go to <a href="https://www.mql5.com/en">https://www.mql5.com/en</a>  and click “Create an account” in the upper right corner.</p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >2</span>Choose your Login</h5>

                                    <img src="/assets/13.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Sign up for an account. Choose your login (username) and your email to sign up. Alternatively you can sign up with your Facebook or Google account. </p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >3</span>Activate</h5>

                                    <p class="mx-5">You receive an email from MQL5 with your credentials that asks you to activate your account. Click “Activate” and go to your account.</p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >4</span>Login to MT4 Platform on your computer
                                    </h5>
                                    <img src="/assets/19.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Open your MT4 platform application in your Windows environment and go to Menu >> Tools >> Options and click on the Community tab. There you put your Login and Password of your MQL5 account and click OK.
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >5</span> Search for Fort Knox Global Signal
                                    </h5>
                                    <img src="/assets/20.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Go to the search area of your MT4 platform, on the upper right corner (where the magnifying glass is), type in “Fort Knox Global” you want to subscribe and click Enter.
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >6</span>Select Fort Knox Global Signal
                                    </h5>
                                    <img src="/assets/21.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">You will see lower, in the Terminal Window of your MT4 platform, the results of your search. Click on the “Fort Knox Global”  signal to subscribe.
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >7</span>Subscribe to Fort Knox Global signal
                                    </h5>
                                    <img src="/assets/22.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Click on the green Subscribe for $$ USD button on the right side of your MT4's Terminal Window.
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >8</span>Select the payment method and complete payment for your subscription.
                                    </h5>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >9</span>In the Options >> Signals window that opens, tick the following options:
                                    </h5>
                                    <img src="/assets/23.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">
                                        <ul class="mql5-list">
                                            <li>Agree to the terms of use of the signals service</li>
                                            <li>Enable real time signal subscription</li>
                                            <li>Copy Stop Loss and Take Profit levels</li>
                                            <li>Synchronise positions without confirmations</li>
                                        </ul>
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>10</span><div>In the Use no more than: ...... of your deposit (95% maximum) field below, put the percentage of your capital, you want to participate in signal's copying.</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>11</span><div>In the In the Stop if equity is less than: ...... field, put the amount of your capital, you want to preserve, or leave it empty. .</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>12</span><div> In the Deviation/Slippage field, select an option and click OK in the Options window to close it.</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>13</span><div> Now you have completed the subscription process and the only thing you need is a VPS (Virtual Hosting) in order to copy the signal 24/7 without having your MT4 platform open all day long.</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>14</span><div>Go to your account details in the Navigator window of your MT4 platform, right click  in your account number or name and select the option Register a Virtual Server.</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >15</span> In the Virtual Hosting Wizard window that opens, the VPS with the lower Ping, ms value is presented automatically.
                                    </h5>
                                    <img src="/assets/24.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>
                                    <p class="mx-5">Click on the green Subscribe for $$ USD button on the right side of your MT4's Terminal Window.
                                    </p>
                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>16</span><div> Select your payment plan for 1, 3, 6 or 12 months and click Next.</div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>17</span><div> Tick the: I agree to the virtual hosting service rules and the auto renewal option below (if you want) and click Next. </div></h5>
                                    <img src="/assets/25.png" class="img-fluid  mx-5 mb-3" width="400px" alt="image"/>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>18</span><div>  Complete your payment for the VPS Service. </div></h5>

                                </div>
                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4 d-flex"> <span>19</span><div>   In the next window, tick the Migrate signal: account and settings option, click End and you are done. </div></h5>

                                </div>








                                {/* <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >4</span>Select Signals Tab</h5>

                                    <img src="/assets/14.png" class="img-fluid  mx-5 mb-3" width="400px" />
                                    <p class="mx-5">Once logged in go to the “Signals” section tab in the top menu bar. </p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >5</span> Choose Metatrader 4</h5>
                                    <img src="/assets/16.png" class="img-fluid  mx-5 mb-3" width="400px" />
                                    <p class="mx-5">From the drop down as seen, choose “MetaTrader 4” </p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >6</span>  Search for Fort Knox Global Signal</h5>
                                    <img src="/assets/17.png" class="img-fluid  mx-5 mb-3" width="400px" />
                                    <p class="mx-5">“Type in “Fort Knox Global” in to search bar on the right. </p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >7</span> Select the Fort Knox Global Gold Account Copy Trading Signal</h5>
                                    <img src="/assets/18.png" class="img-fluid  mx-5 mb-3" width="400px" />
                                    <p class="mx-5">Select the “Fort Knox Global” account.   Verify that your selection is as shown: with the account name “Abayomi Majekodumi Omojokun”</p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >8</span>Click on copy button</h5>

                                    <p class="mx-5">Click on the button “Copy for x$” in the right upper corner and you will be redirected to a page that asks you to select the method of payment. </p>
                                </div>

                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >9</span>Select your preferences</h5>

                                    <img src="/assets/15.png" class="img-fluid  mx-5 mb-3" width="400px" />
                                    <p class="mx-5">After payment, you will be redirected to a window to finish with your signal connection setup.Select your preferences.</p>
                                </div>



                                <div class="primary-card-shadow1 pb-2 mb-4">
                                    <h5 class=" heading mt-4"> <span >10</span>Click on copy button</h5>

                                    <p class="mx-5">You will receive an email confirming the subscription to the profitable Forex signal provider Fort Knox.</p>
                                </div> */}
                            </div>
                        </div>


                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}
