import React, { Component } from 'react'
import baseURL from './baseurl'
import { Helmet } from 'react-helmet'

export default class Ruleof72calculator extends Component {
    state = {
        interestrate: 0,
        amount:10000,
        defaultvalue: true,
        periodstodouble: 0,
        validationinterest:false,
        validationamount:false
    }
    submitdata = async (e) => {
        e.preventDefault()
        if(this.state.amount<1|| isNaN(this.state.amount)){
            await this.setState({validationamount:true,validationinterest:false})
        }
        else if(this.state.interestrate>100 || this.state.interestrate<0 || isNaN(this.state.interestrate)){
            await this.setState({validationinterest:true,validationamount:false})
        }
        else{
            await this.setState({ defaultvalue: false, validationinterest:false,validationamount:false })
        this.componentDidMount()
        }
        
    }
    async componentDidMount() {
    
        if (this.state.defaultvalue) {
           await baseURL.get('/get-data/calculator-data').then(async res => {
                await this.setState({ interestrate: parseFloat(res.data.Monthlyroi) })
            })
        }
        var double = 72 / this.state.interestrate
        await this.setState({ periodstodouble: (double).toFixed(2) })
    }
    render() {
        return (
            <div class="container">
                 <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Compounding calculator (72 rule)" />
                    <meta name="description" content="Compounding calculator (72 rule) forecast the return needed to double your money in a certain interval of time or in how much time the investment doubles " />
                    <title>Compounding calculator (72 rule) - Time needed to double </title>
                </Helmet>
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-6">
                        <div class="calculator-form">
                        <form onSubmit={(e) => this.submitdata(e)}>
                            <div class="form-group">
                                <label for="amount">Enter amount</label>
                                <input type="number" class="form-control" id="amount" value={this.state.amount}
                                    onChange={(e) => this.setState({ amount: parseFloat(e.target.value) })} />                           
                                {this.state.validationamount && <div class="validation-bar">*enter amount</div>}
                             </div>
                            <div class="form-group">
                                <label for="interestrate">Interest Rate</label>
                                <input type="number" class="form-control" id="interestrate" value={this.state.interestrate}
                                    onChange={(e) => this.setState({ interestrate: parseFloat(e.target.value) })} />
                                {this.state.validationinterest && <div class="validation-bar">*should be 1-100</div>}                           
                             </div>
                            <button class="btn btn-primary" type='submit'>Calculate</button>
                        </form>
                        </div>
                    </div>
                    <div class="col-xl-6 blue-box d-flex align-items-center">
                        <div class="pl-5">
                        <p class="mb-0">Periods to double</p>
                        <h5 class="mb-0">{this.state.periodstodouble}</h5>
                        </div>
                    </div>
                </div>
                <section class="defination">
                    <div class="container">
                    <div class ="row">
                    <div class ="col-xl-12">
                    <div class ="defination-box">
                    <h2>Definition</h2>
                    <p>The Rule of 72 is a simple and fast formula, a popular shortcut, used to find out the approximative number of years needed to double a sum of money invested at a certain rate of return.</p>
                    <h4>To retain</h4>
                    <ul>
                    <li>The Rule of 72 takes into consideration the compound effect.</li>
                    <li>The Rule of 72 is usually applied to elements that increase exponentially, for example inflation, the Gross Domestic Product.</li>
                    <li>The Rule of 72 is very useful to show the effects of annual rates of return on an investment in the long run or the effects of annual fees.</li>
                    </ul>
                    <h4>The Rule of 72 Formula</h4>

                    <p>
                    <b>Years to Double = 72 / Interest Rate</b><br/>
                    The Interest Rate = Rate of return on investment<br/>
                    The Compounding Calculator based on the Rule of 72, lets you find out fast how much time do you need to double your investment.

                    </p>
                    </div>


                    <div class ="defination-box">
                    <h2>Terms of the Compounding Calculator </h2>
                    <h4>Inputs</h4>
                    <ul >
                    <li >
                    <b>Rule of 72 estimate: </b>  The calculator will indicate an approximative number of years needed for your initial capital to double.
                    </li>
                    <li >
                    <b>Rule of 72 exact answer: </b>  The calculator will indicate the exact number of years needed for your initial capital to double.
                    </li>
                    <li >
                    <b>Years to Double Investment: </b>  Insert the number of years you want your investment to double.For example 3 years.
                    </li>
                    </ul>
                    <h4>Result</h4>

                    <ul >
                    <li >
                    <b>The Annual Rate of Interest for your investment to double, Rule of 72 estimate: </b>  The calculator will indicate the approximative rate of interest you need to obtain to double your money in x years.
                    </li>
                    <li >
                    <b>The Annual Rate of Interest for your investment to double, Rule of 72 exact number: </b>  The calculator will indicate the exact rate of interest you need to obtain to double your money in x years.
                    </li>
                    </ul>
                    <h4>Example</h4>
                    <p>Let’s say you have a potential option of investments that is aiming to deliver a 42% annual interest rate.You will need approximately 1.71 years to double your investment, and more exactly 1.98 years.</p>
                    <p>The reverse: if you want to double an investment in let’s say 3 years, you need to look for an annual interest rate of 24%, approximately, and more exactly of 25.99%.</p>
                    </div>
                    </div>
                    </div>

                    </div>

                </section>
            </div>
        )
    }
}
