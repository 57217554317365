import React, { Component } from 'react'
import PerformanceTable from './performance-table';
import PerformanceGraph from './performance-graph';
import Navbar from './navbar';
import Footer from './footer';
import baseURL from './baseurl';
import Aos from 'aos';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga'

ReactGA.initialize('UA-208545193-1')

export default class Performance extends Component {
  state = {
    data: {},
    startingcapital:0,
    CROI:0
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname)
    baseURL.get('/get-data/performance-table-data').then(async res => {
      await this.setState({ data: res.data.data,
        startingcapital: res.data.data.startingcapital.toLocaleString('en-US')
      })
    })
    await baseURL.get('/get-data/startingdate').then(async res=>{
      var date = new Date()
      var ty = date.getFullYear()
      var tm = ("0" + (date.getMonth() + 1)).slice(-2)
      var td = ("0" + date.getDate()).slice(-2)
      var todaydate = `${tm}/${td}/${ty}`
  
      await baseURL.get(`get-data/graph-data?from=${res.data.startdate}&to=${todaydate}`)
      .then(async respo => {
         this.setState({CROI:respo.data.actual[respo.data.actual.length-1].CROI}) 
      })
   })
    Aos.init({
      duration: 2000
    })
  }
  HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9', color: 'black', maxWidth: 220, fontSize: 12, border: '1px solid black',
    },
  }));

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="keywords" content="investment performance" />
          <meta name="description" content="Watch live our investment performance and evaluate the returns and net profits our strategy can deliver for you" />
          <title>Investment Performance is the focal interest for investors</title>
        </Helmet>
        <Navbar />
        <section class="Performance-subheading">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="text-center ">
                  <h4>Performance</h4>
                </div>
              </div>
            </div>

          </div>

        </section>

        <section class="Performance1-section">
          <div class="container">



            <div class="row d-flex justify-content-center">

              <div class="col-xl-8 d-flex align-items-center">
                <div >

                </div>
              </div>
            </div>
            <div >
              <PerformanceGraph />
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="d-flex justify-content-center mt-3">
                </div>
              </div>
            </div>
          </div>

        </section>
        <section class="Performance-subheading mb-5">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="text-center ">
                <div>
                <h6>View Fort Knox Global performance on MQL5</h6>
                 {/* <p className="mb-0"><a href="https://www.mql5.com/en/signals/1204063?source=Unknown" target="_blank">Fort Knox Global</a></p> */}
                 <p><a href="https://www.mql5.com/en/signals/1257775?source=Site+Signals+MT4+Tile+All+Search%3aFORT+KNOX" target="_blank">Fort Knox Global Hedging Account</a></p>
                 <p><a href="https://www.mql5.com/en/signals/1428265?source=Site+Signals+MT4+Tile+All+Search%3aFORT+KNOX" target="_blank">Fort Knox Global on Vantage1</a></p>
                 <p><a href="https://www.mql5.com/en/signals/1479219?source=Site+Signals+MT4+Tile+All+Search%3aFORT+KNOX" target="_blank">Fort Knox Global on Vantage2</a></p>
                 {/* <p><a href="https://www.mql5.com/en/signals/1396639?source=Site+Signals+MT5+Tile+All+Search%3aFORT+KNOX" target="_blank">Fort Knox Global on LegacyFX</a></p> */}
                </div>
                </div>
              </div>
            </div>

          </div>

        </section>
        <section class="list-section">
          <div class="container">

            <div class="row ">



              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12  " data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                <div class="trades-table primary-card-shadow">
                  <h5>Trades</h5>
                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                      Number of trades per day
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Average number of positions opened in a day, only averaged over the days trades were made. </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Average for days with trades
                    </div>
                    <span >{this.state.data.tradesperday}</span>
                  </div>
                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                      Number of trades per month
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Number of trades placed on the market in a month </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Average for days
                    </div>
                    <span >{this.state.data.tradespermonth}</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">


                      Total number of trades to date
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Total number of trades placed on the market since the account was launched </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Cumulative
                    </div>
                    <span >{this.state.data.totaltradetodate}</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                      Number of days trading
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Number of days since the account was open </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Since start {this.state.data.startdate}
                    </div>
                    <span >{this.state.data.dayoftrading}</span>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 " data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                <div class="trades-table primary-card-shadow ">
                  <h5>Risk</h5>
                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Risk to Capital Ratio
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Percentage of the capital risked on a trade as average </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Average
                    </div>
                    <span >{this.state.data.risktocapitalratio}%</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Closed at Profit
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Percentage of trades closed at a on profit reported to the total number of trades </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Percentage of trades closed at profit
                    </div>
                    <span >{(this.state.data.closedatprofitpercent)}%</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Closed at Loss
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Percentage of trades closed on loss reported to the total number of trades </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Percentage of trades closed at loss
                    </div>
                    <span >{this.state.data.closedatlosspercent}%</span>
                  </div>


                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12  " data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">
                <div class="trades-table primary-card-shadow ">
                  <h5>Performance</h5>
                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        ROI
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Total profit for the trading activity expressed as percentage of the starting capital </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Cumulative profit of closed trades
                    </div>
                    <span >{this.state.CROI}%</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Daily Rate of Return
                        <this.HtmlTooltip arrow placement="right"
                          title={<>The net profit of all trades closed in a given day gain calculated on a daily basis  </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Average  ROR of closed trades
                    </div>
                    <span >{this.state.data.averageROI}</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Investment
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Value of initial deposit made on trading account Sum of money/capital deposited on the trading account </>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Intial deposit
                    </div>
                   <span>$ {this.state.startingcapital}</span> 
                   
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Average Profit
                        <this.HtmlTooltip arrow placement="right"
                          title={<> Average number of pips gained on profitable trades</>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      per profitable trades
                    </div>
                    <span >{this.state.data.averageprofitinpips}</span>
                  </div>

                  <div class="trades-list d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        Average Loss
                        <this.HtmlTooltip arrow placement="right"
                          title={<>Average number of pips lost on loss trades</>}>
                          <button className="abcd"> <i class="fas fa-info-circle text-primary"></i></button>
                        </this.HtmlTooltip>
                      </div>
                      Per loss trade
                    </div>
                    <span >{this.state.data.averagelossinpips}</span>
                  </div>


                </div>
              </div>

            </div>

            <div class="d-flex justify-content-end">
            </div>


          </div>
        </section>





        <section class="How-are-doing-section">
          <div class="container">
            <div class="row ">
              <div class="col-xl-12">
                <div class="text-center ">
                  <h2 class="section-heading">Our Trades </h2>

                </div>
              </div>
            </div>
            <div class="row ">
              <div class="col-xl-12 ">
                {/* <div  className="performance-table"> */}
                {/* <img src="assets/graph2.svg" alt="" class="img-fluid" /> */}
                <PerformanceTable />
                {/* </div> */}
              </div>


            </div>
          </div>
        </section>

        {/* <section class="">
          <div class="container">
            <div class="row mb-5">
              <div class="col-xl-12">
                <div class="text-center">
                  <h2 class="section-heading mb-4">Cumulative rate of return </h2>
                </div>
              </div>
            </div>
            <div class="row ">
              <div class="col-xl-6">

                <div class="Outperform text-center">
                  <h4>Outperforming our projections</h4>
                  <p> +30%</p>
                  <br></br>
                  <a href="" class="btn btn-primary btn curve mr-3">View Table</a>
                  <a href="" class="btn btn-primary btn curve">Calculate Your's Projections</a>
                </div>


              </div>
              <div class="col-xl-6 ">
                <div class="d-flex align-items-center">
                  <img src="assets/graph3.svg" alt="" class="img-fluid" />

                </div>
              </div>


            </div>
          </div>
        </section> */}


        <Footer />

      </>
    )
  }
}
