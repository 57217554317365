import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'
import { Link } from 'react-router-dom'
import Aos from 'aos'

export default function Signalcopytrading() {
    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    })
    return (
        <div>
            <Navbar />
            <section class="Performance-subheading">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="text-center">
                                <h4>Get Started</h4>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section class="services">
                <div class="container text-center">
                    Call us at +1 (416) 479-5457 or email us at <a href="mailto:trade@fortknoxglobal.com"> trade@fortknoxglobal.com</a> to book an account opening appointment.
                </div>
                {/* <div class="container aos-init aos-animate" data-aos="fade-up">



                    <div class="row">
                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                            <div class="icon-box">
                                <div class="icon"><i class="far fa-copy"></i></div>
                                <h4><Link to='/get-started/copy-trading/forexaccount'>1. Open a Forex Trading Account</Link></h4>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                            <div class="icon-box">
                                <div class="icon" ><i class="far fa-chart-bar"></i></div>
                                <h4><Link to='/get-started/copy-trading/metatrader'>2. Download the Metatrader Platform</Link></h4>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
                            <div class="icon-box">
                                <div class="icon"><i class="far fa-calendar-check"></i></div>
                                <h4><Link to='/get-started/copy-trading/mql'>3. Open an MQL5 Account and Choose Fort Knox as Forex Signal Service</Link></h4>
                            </div>
                        </div>
                    </div>

                </div> */}
            </section>
            <Footer />
        </div>
    )
}
